import { useState, useEffect } from 'react';
import { ReactComponent as PicsImagefile } from "../pics/imagefile.svg";

import ModuleMindmap_ShowMindmap from '../composants/Modules/ModuleMindmap_ShowMindmap';

const MindmapEleve = ({cours}) => {
    const [currentMindmap, setCurrentMindmap] = useState(null);

    return (<>
        <div className="containerFichiersPartage">
            {cours.modules.cartementale.map((file, index) => (
                <div
                    key={index}
                    className="partageItem partageHover"
                    style={{ justifyContent: "space-between", cursor: 'pointer' }}
                    onClick={() => setCurrentMindmap(file)}
                >
                    <div>
                        <div className="actionButton">
                            <PicsImagefile />
                        </div>
                        <span>{file.nomFichier}</span>
                    </div>
                </div>
            ))}

            {cours.modules.cartementale.length === 0 &&
                <div style={{ width: '100%' }}><div className="containerFichiersPartage"></div>
                    <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
                        Vous n'avez encore généré aucune carte mentale.</p></div>
            }
        </div>
        {currentMindmap &&
                        <ModuleMindmap_ShowMindmap
                            currentMindmap={currentMindmap}
                            setCurrentMindmap={setCurrentMindmap}
                            canModify={false}
                        />
                    }
    </>)
}

export default MindmapEleve;