import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router";
import "./App.css";
import { serverPHP } from "./consts";

import LoginPage from "./interface_prof/LoginPage";
import ConfirmInscription from "./composants/ConfirmInscription";
import MainMenuProf from "./composants/MainMenuProf";
import MainMenuEleve from "./composants/MainMenuEleve";
import { useNavigate } from 'react-router';

function App({ menu }) {
  const { id, id2 } = useParams();
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [updateUser, setUpdateUser] = useState(false);
  const [matieres, setMatieres] = useState([]);
  const [userInfo, setUserInfo] = useState({ id: null, nom: null, prof: false });
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  // Fetch matieres only once
  useEffect(() => {
    const fetchMatieres = async () => {
      try {
        const response = await fetch(serverPHP + "/db/getMatieres.php");
        const data = await response.json();
        setMatieres(data);
      } catch (error) {
        console.error('Error fetching matieres:', error);
      }
    };
    fetchMatieres();
  }, []);

  // Update user data when triggered
  useEffect(() => {
    if (updateUser) {
      setUpdateUser(false);
      const updateUserInfo = async () => {
        try {
          const response = await fetch(serverPHP + "/db/updateUser.php", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              id: userInfo.id,
              nom: userInfo.nom,
              instructions_default: userInfo.instructions_default,
              accroche_default: userInfo.accroche_default,
              niveau_reponses: userInfo.niveau_reponses,
              longueur_reponses: userInfo.longueur_reponses,
            }),
          });
          if (!response.ok) {
            const data = await response.json();
            console.error("Erreur lors de la mise à jour de l'utilisateur :", data.error || "Erreur inconnue");
          }
        } catch (error) {
          console.error("Erreur lors de la requête de mise à jour de l'utilisateur :", error);
        }
      };
      updateUserInfo();
    }
  }, [updateUser, userInfo]);

  // Check user session when the app loads
  useEffect(() => {
    if (menu !== "confirm") {
      const checkUserSession = async () => {
        try {
          const response = await fetch(serverPHP + "/db/checkSession.php", {
            method: "GET",
            credentials: "include",
          });
          const data = await response.json();
          if (data.status === 'success') {
            setUserInfo({
              id: data.id,
              nom: data.nom,
              prof: data.prof,
              instructions_default: data.instructions_default,
              accroche_default: data.accroche_default,
              niveau_reponses: data.niveau_reponses,
              longueur_reponses: data.longueur_reponses,
              matieres: data.matieres
            });
          }
        } catch (error) {
          console.error("Erreur lors de la vérification de la session :", error);
        } finally {
          setLoading(false);
        }
      };
      checkUserSession();
    } else {
      setLoading(false);
    }
  }, [menu]);

  // Memoized condition for checking if user is signed in
  const isUserSignedIn = useMemo(() => userInfo.id !== null, [userInfo.id]);

  if (loading) {
    return <div className="loading-screen"></div>;
  }

  if (isUserSignedIn && menu === 'inscription') {
    navigate("/");
  }

  return (
    <>
      {menu === "confirm" ? (
        <ConfirmInscription id={id} setError={setError} setInfo={setInfo} />
      ) : (
        <>
          {isUserSignedIn ? (
            userInfo.prof ? (
              <MainMenuProf
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                matieres={matieres}
                chatbot_id={menu === 'chatbot' ? parseInt(id) : null}
              />
            ) : (
              <MainMenuEleve
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                setUpdateUser={setUpdateUser}
                chatbot_id={menu === 'groupe_et_chatbot' ? parseInt(id2) : null}
                groupe_id={menu === 'groupe' || menu === 'groupe_et_chatbot' ? parseInt(id) : null}
              />
            )
          ) : (
            <div className="container">
              <LoginPage
                setUserInfo={setUserInfo}
                error={error}
                setError={setError}
                info={info}
                setInfo={setInfo}
                matieres={matieres}
                loadSignUpStep={menu === 'inscription' ? 1 : 0}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default App;
