// ModuleDiapo_parametres.js

import React, { useState, useEffect } from 'react';
import './ModuleMindmap.css';
import { serverPHP } from '../../consts';
import LoadingCircle from '../LoadingCircle';
import Modal from './Modal'; // Import the separate Modal component

const ModuleDiapo_parametres = ({
  contenuFichier,
  setContenuFichier,
  setTitreMenu,
  cours,
  setCours,
  setCurrentDiapo,
  setFichierFromSources
}) => {

  const DiapoPrompts = [
    {
      label: "Support de cours",
      prompt: "Génère un diaporama qui puisse servir de support de cours pour ce document. Il faut donc respecter les principales articulation, et reproduire fidèlement les éléments importants (les citations, les nombres, les sources, etc.)."
    },
    {
      label: "Synthèse",
      prompt: "Génère un diaporama qui synthétise ce document. Il faut réduire autant que possible le nombre de slides, et n'indiquer que les informations capitales."
    },
    {
      label: "Personnaliser",
      prompt: ""
    }
  ];
  
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState(DiapoPrompts[0].prompt);
  const [nomFichier, setNomFichier] = useState("Diaporama 1");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setTitreMenu("2. Paramètres");
  }, [setTitreMenu]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = (updatedContent) => {
    setContenuFichier(updatedContent);  // Update content only on modal close
    setIsModalOpen(false);
  };

  const handleGenerateDiapo = () => {
    if (!nomFichier.trim()) {
      setNomFichier("Diaporama 1");
    }

    setLoading(true);
    fetch(`${serverPHP}/moduleDiapo_handleCreation.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prompt: prompt,
        contenuFichier: contenuFichier,
        nomFichier: nomFichier,
        assistant_id: cours.id
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Response from server:', data);
        if (data) {
          setCours(prevCours => ({
            ...prevCours,
            modules: {
              ...prevCours.modules,
              diapo: [...(prevCours.modules.diapo || []), data]
            }
          }));
          setLoading(false);
          setFichierFromSources(null);
          setContenuFichier(null);
          setCurrentDiapo(data);
        } else {
          console.error('Invalid data format received:', data);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  };

  const handleNomFichierBlur = () => {
    if (!nomFichier.trim()) {
      setNomFichier("Diaporama 1");
    }
  };

  const handlePromptSelection = (event) => {
    const selectedPrompt = event.target.value;
    setPrompt(selectedPrompt);
  };

  return (
    <>
      <div className="moduleContainer" style={{ position: 'relative', height: '450px' }}>
        {loading ? (
          <>
            <LoadingCircle taille={150} />
            <div className="generationQCMEnCours">
              Génération du diaporama en cours...<br /><span style={{ fontSize: '14px' }}>Cette opération peut prendre une vingtaine de secondes</span>
            </div>
          </>
        ) : (
          <>
            <div className="moduleTitre">Choisissez les <strong>paramètres</strong></div>
            <div className="login-form small" style={{ marginLeft: 0 }}>
              <div className="input-group">
                <label className="login-label parametresQCM">Nom du diaporama :</label>
                <input
                  type="text"
                  value={nomFichier}
                  onChange={(e) => setNomFichier(e.target.value)}
                  onBlur={handleNomFichierBlur}
                  className="login-input"
                  style={{ width: '150px' }}
                />
              </div>
              <div className="input-group">
                <label className="login-label parametresQCM">Texte chargé :</label>
                <button onClick={openModal} className="login-button boutonQCM" style={{ margin: 0, maxWidth: '150px' }}>Modifier</button>
              </div>
              <div className="input-group">
                <label className="login-label parametresQCM">Instructions :</label>
                <select
                  onChange={handlePromptSelection}
                  style={{ width: '100%', maxWidth: '150px' }}
                >
                  {DiapoPrompts.map((item, index) => (
                    <option key={index} value={item.prompt}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                className="modal-textarea textarea"
              />
            </div>
            <button
              className="login-button"
              style={{ width: '250px', margin: 'auto' }}
              onClick={handleGenerateDiapo}
            >
              Générer le diaporama
            </button>
          </>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        initialContent={contenuFichier}
      />
    </>
  );
};

export default ModuleDiapo_parametres;
