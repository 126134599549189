// ModuleQCM_eleve.js

import React, { useState, useEffect, useRef } from 'react';
import './ModuleQCM_eleve.css';

const ModuleQCM_eleve = ({ cours, setModuleActif, setQuestionsQCM, setShowModules }) => {
  const qcms = cours.modules.qcm;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswerId, setSelectedAnswerId] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [errorCount, setErrorCount] = useState(0); // Compteur d'erreurs
  const [questionsWithErrors, setQuestionsWithErrors] = useState([]); // Indices des questions avec erreurs
  const hasSetQuestionsQCM = useRef(false); // Pour éviter les appels multiples

  /*// Au chargement du composant, on réinitialise les questions QCM
  useEffect(() => {
    setQuestionsQCM([]);
  }, [setQuestionsQCM]);*/

  // Vérifie si le QCM est terminé pour passer les questions avec erreurs
  useEffect(() => {
    if (currentQuestionIndex >= qcms.length && !hasSetQuestionsQCM.current) {
      // Obtenir les intitulés des questions avec erreurs
      const questionsWithErrorsTitles = questionsWithErrors.map(
        (index) => qcms[index].question
      );
      // Mettre à jour les questions QCM avec les intitulés des questions où il y a eu des erreurs
      setQuestionsQCM(questionsWithErrorsTitles);
      hasSetQuestionsQCM.current = true;
    }
  }, [currentQuestionIndex, qcms, questionsWithErrors, setQuestionsQCM]);

  if (!qcms || qcms.length === 0) {
    return <div>Aucun QCM disponible.</div>;
  }

  if (currentQuestionIndex >= qcms.length) {
    // Tous les QCM sont terminés
    //const totalQuestions = qcms.length;
    const pluralErrors = questionsWithErrors.length > 1 ? 'erreurs' : 'erreur';
    const msgErreurs =
      errorCount === 0 ? (
        <>Vous n'avez fait aucune erreur !</>
      ) : (
        <>
          Vous avez fait <strong>{questionsWithErrors.length}</strong> {pluralErrors}.
        </>
      );
    const feedbackMessage =
    questionsWithErrors.length === 0
        ? "C'est parfait, vous avez bien assimilé le cours !"
        : questionsWithErrors.length <= qcms.length / 4
        ? "C'est très bien, le cours est bien compris."
        : questionsWithErrors.length <= qcms.length / 2
        ? 'C\'est correct, mais quelques éléments doivent être revus.'
        : "C'est trop fragile : relisez bien le cours, et retentez votre chance.";

    return (
      <div className="moduleContainer">
        <div className="moduleTitre">
          <strong>Fin du QCM</strong>
        </div>
        <p>{msgErreurs}</p>
        <p>{feedbackMessage}</p>
        <button
          className="login-button"
          onClick={() => {
            setModuleActif(null);
            setShowModules(false);
          }}
          style={{ width: '300px', alignSelf: 'center', marginTop: '10px' }}
        >
          Fermer
        </button>
      </div>
    );
  }

  const currentQcm = qcms[currentQuestionIndex];

  const handleAnswerClick = (answer) => {
    setSelectedAnswerId(answer.reponse_id);
    if (answer.bonne_reponse) {
      // Bonne réponse
      setIsCorrect(true);
    } else {
      // Mauvaise réponse : incrémente le compteur d'erreurs
      setErrorCount(errorCount + 1);
      setIsCorrect(false);
      // Ajouter l'indice de la question actuelle aux questions avec erreurs si elle n'y est pas déjà
      setQuestionsWithErrors((prevQuestions) => {
        if (!prevQuestions.includes(currentQuestionIndex)) {
          return [...prevQuestions, currentQuestionIndex];
        }
        return prevQuestions;
      });
    }
    setShowFeedback(true);
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    setSelectedAnswerId(null);
    setIsCorrect(null);
    setShowFeedback(false);
  };

  return (
    <div className="moduleContainer">
      <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
      <div className="moduleTitre">{currentQcm.question}</div>
      <div style={{fontSize:'18px', fontWeight:'bold', flexShrink:0, marginLeft:'20px'}}>{currentQuestionIndex + 1} / {cours.modules.qcm.length}</div>
      </div>
      {currentQcm.answers.map((answer) => (
        <div
          key={answer.reponse_id}
          className={`partageItem answerItem ${
            selectedAnswerId === answer.reponse_id
              ? isCorrect
                ? 'selectedCorrect'
                : 'selectedIncorrect'
              : isCorrect
              ? 'no-hover'
              : ''
          }`}
          onClick={() => !isCorrect && handleAnswerClick(answer)}
          style={{
            cursor: isCorrect ? 'default' : 'pointer',
          }}
        >
          {answer.text}
        </div>
      ))}
      {showFeedback && (
        <div
          className="feedback"
          style={{ color: isCorrect ? 'green' : 'red' }}
        >
          {(() => {
            const selectedAnswer = currentQcm.answers.find(
              (a) => a.reponse_id === selectedAnswerId
            );
            const feedbackText = selectedAnswer.feedback;

            if (feedbackText && feedbackText.trim() !== '') {
              return feedbackText;
            } else {
              return isCorrect
                ? "Bravo, c'est la bonne réponse !"
                : "Ce n'est pas la bonne réponse, essaye encore !";
            }
          })()}
        </div>
      )}
      {isCorrect && (
        <button
          className="login-button"
          onClick={handleNextQuestion}
          style={{ width: '150px', alignSelf: 'center' }}
        >
          Continuer
        </button>
      )}
    </div>
  );
};

export default ModuleQCM_eleve;
