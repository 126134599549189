import sha256 from 'crypto-js/sha256';

function digestMessage(message) {
  if (window.crypto && crypto.subtle && crypto.subtle.digest) {
    return crypto.subtle.digest('SHA-256', new TextEncoder().encode(message))
      .then(hashBuffer => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
      });
  } else {
    // Au cas où crypto ne marcherait pas (ex: tel portables)
    return Promise.resolve(sha256(message).toString());
  }
}



function encodeHash(hash) {
  const encodingTable = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const decodingTable = 'yw53h2dnsxfcbkl80vmqzaej6pu1gtrio947';
  let encodedHash = '';

  for (let i = 0; i < hash.length; i++) {
    const char = hash[i];
    const position = encodingTable.indexOf(char.toLowerCase());
    if (position !== -1) {
      let newPosition = (position + (i + 1)) % decodingTable.length;
      encodedHash += decodingTable[newPosition];
    } else {
      encodedHash += char; // Si le caractère n'est pas dans la table, on le garde tel quel
    }
  }

  return encodedHash;
}

// New encode function that canonicalizes, hashes, and encodes the input
export async function encode(canonicalInputString) {
  const hashHex = await digestMessage(canonicalInputString);
  return encodeHash(hashHex);
}