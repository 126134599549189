import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './ComposantInputBar.css';
import { ReactComponent as PicsSettings } from "../pics/settings.svg";


const levels = ["Débutant", "Intermédiaire", "Avancé", "Expert"];
const lengths = ["Une phrase", "Un paragraphe", "Une page", "Un cours"];

const ComposantInputBar = ({ setUpdateUser, userInfo, setUserInfo }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false); // Manage animation state

    const popupRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setShowPopup(false);
                setIsAnimating(true);
            }
        };

        const handleResize = () => {
            if (showPopup) {
                setShowPopup(false);
                setIsAnimating(true);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('resize', handleResize);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('resize', handleResize);
        };
    }, [showPopup]);

    // Helper function to calculate button position
    const calculatePosition = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            return { top: rect.top, left: rect.left, width: rect.width };
        }
        return { top: 0, left: 0 };
    };

    useEffect(() => {
        if (!showPopup && isAnimating) {
            const timeout = setTimeout(() => {
                setIsAnimating(false);
            }, 300); // Duration of the collapse animation

            return () => clearTimeout(timeout);
        }
    }, [showPopup, isAnimating]);

    const handleButtonClick = () => {
        if (showPopup) {
            setShowPopup(false);
            setIsAnimating(true);
        } else {
            setShowPopup(true);
            setIsAnimating(true);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <div
                className="fleche"
                onClick={handleButtonClick}
                style={{ marginRight: '10px', marginLeft: '0' }}
                ref={buttonRef}
            >
                <PicsSettings
                    height={50}
                    width={50}
                    className="boutonParametresEleve"
                    alt="Paramètres du chatbot"
                />
            </div>
            {(showPopup || isAnimating) &&
                ReactDOM.createPortal(
                    <div
                        className={`popupDiv ${showPopup ? 'show' : 'hide'}`}
                        ref={popupRef}
                        style={{
                            top: calculatePosition().top - 210 + 'px',
                            left: calculatePosition().left + 'px',
                            padding: "20px 40px"
                        }}
                    >
                        {/* Niveau scolaire */}
                        <div className="slider-container">
                            <div className="slider-title">
                                Niveau de la réponse : <strong>{levels[userInfo.niveau_reponses]}</strong>
                            </div>
                            <Slider
                                min={0}
                                max={levels.length - 1}
                                step={1}
                                value={userInfo.niveau_reponses}
                                onChange={(value) => {
                                    setUserInfo((prev) => ({ ...prev, niveau_reponses: value }));
                                    setUpdateUser(true);
                                }}
                                marks={{
                                    0: "Débutant",
                                    1: "Intermédiaire",
                                    2: "Avancé",
                                    3: "Expert"
                                }}
                                included={false}
                            />
                        </div>

                        {/* Longueur de la réponse */}
                        <div className="slider-container">
                            <div className="slider-title">
                                Longueur de la réponse : <strong>{lengths[userInfo.longueur_reponses]}</strong>
                            </div>
                            <Slider
                                min={0}
                                max={lengths.length - 1}
                                step={1}
                                value={userInfo.longueur_reponses}
                                onChange={(value) => {
                                    setUserInfo((prev) => ({ ...prev, longueur_reponses: value }));
                                    setUpdateUser(true);
                                }}
                                marks={{
                                    0: "Une phrase",
                                    1: "Un paragraphe",
                                    2: "Une page",
                                    3: "Un cours"
                                }}
                                included={false}
                            />
                        </div>
                    </div>,
                    document.body
                )}
        </div>
    );
};

export default ComposantInputBar;
