import React from 'react';
import './Header.css';
import ProfileButton from './ProfileButton';
import { ReactComponent as PicsFleche } from "../pics/fleche.svg";
import { ReactComponent as PicsPlusRond } from "../pics/plus_rond.svg";
import { modules_full } from '../consts';
import { useNavigate } from 'react-router';


const HeaderEleve = ({ cours, userInfo, setUserInfo, setShowModules, groupe_id }) => {

  const modules = modules_full(cours);
  const navigate = useNavigate();


  const formatDate = (date) => {
    if (!date) return '';

    const validDate = typeof date === 'string' ? new Date(date) : date;
    if (isNaN(validDate)) return '';

    return validDate.toLocaleDateString('fr-FR', {
      day: 'numeric',
      month: 'long',
    });
  };

  return (
    <div className="header-banner">
      <div style={{ flex: 1, display: 'flex' }}>
        <div className="retourListeEleve" onClick={() => navigate("/groupe/" + groupe_id)}>
          <PicsFleche width={50} height={50} alt="Retour" />
        </div>
        {cours && (
          <>
            <div style={{ marginLeft: '20px' }}>
              <div className="titreDate">
                Cours du{' '}
                <span style={{ fontWeight: 700 }}>
                  {cours?.date ? formatDate(cours.date) : 'Choisissez une date'}
                </span>
              </div>
              <div className="input-container">
                <span className="titreSujet">
                  {cours?.sujet || ''}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        {modules.length > 0 &&
          <div className="boutonSVGEleve" onClick={() => setShowModules((prev) => (!prev))}>
            Ressources
            <div className="ressourcesEleve">

              {modules.filter(m => m.isVisible).map((m, i) => (
                <span key={i}>
                  {React.cloneElement(m.icon, { title: m.statut, className: "smallIconeModuleEleve" })}
                </span>
              ))}
            </div>
          </div>
        }
        <ProfileButton userInfo={userInfo} setUserInfo={setUserInfo} />
      </div>
    </div>
  );
};

export default HeaderEleve;
