import './SeeModules.css';
import {useState, useEffect, useCallback} from 'react';
import { serverPHP } from "../consts";
import { ReactComponent as Croix } from "../chatbot/images/croix.svg";
import { ReactComponent as ChevronDroite } from "../chatbot/images/chevron_droite.svg";
import { modules_full } from '../consts';
import PartageEleve from './PartageEleve';
import ModuleQCM_eleve from './ModuleQCM_eleve';
import MindmapEleve from './MindmapEleve';
import ModuleAudio_ListeAudios from '../composants/Modules/ModuleAudio_ListeAudios';
import ModuleDiapoEleve from './ModuleDiapoEleve';


const SeeModules = ({ cours, setCours, setShowModules, moduleActif, setModuleActif, showSources, setShowSources, userInfo, setQuestionsQCM/*, moduleActif, setModuleActif*/ }) => {
    const [currentOpened, setCurrentOpened] = useState(-1);

    const [currentAudioFile, setCurrentAudioFile] = useState(null);

    //const [sousMenu, setSousMenu] = useState(null);

    const handleTitleClick = useCallback((index, titre) => {
        if (titre === "QCM") {
            setModuleActif("QCM");
        } else {
        if (currentOpened === index) {
            setCurrentOpened(-1);
        } else {
            setCurrentOpened(index);
        }
    }
    }, [currentOpened, setModuleActif]);


    const OptionModule = ({ img, index, titre, opened, description, statut }) => (
        <div className="module moduleEleve" style={{overflow:'hidden', height:opened ? 'auto':'85px'}}>
            <div onClick={() => handleTitleClick(index, titre)}>
                <div style={{ display: 'flex', width: '100%',gap: '20px' }}>
                    {img}
                    <div className="descriptionModule">
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div className="titreModule">{titre}</div>
                        <div style={{fontStyle: 'italic'}}>{statut}</div>
                        </div>
                        <div>{description}</div>
                    </div>
                </div>

            </div>
            <div>
                {titre === "Partage de fichiers" ?
                    <PartageEleve cours={cours} />
                    : titre === "QCM" ?
                    <>QCM</>
                    : titre==="Carte mentale" ?
                    <MindmapEleve cours={cours} /> 
                    : titre==="Audios" ?
                    <ModuleAudio_ListeAudios cours={cours} currentAudioFile={currentAudioFile} setCurrentAudioFile={setCurrentAudioFile} isProf={false} /> 
                    : titre==="Diaporama" ?
                    <ModuleDiapoEleve cours={cours} /> 
                    :
                    <>titre : {titre}</>
                }
                </div>
        </div>
    );

    const modules = modules_full(cours);


    return (<>
        <div className="titreEtCroix" style={{ backgroundColor: 'white' }}>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span data-icon="x" className="fermerCroix" onClick={() => {
                        setShowModules(false);
                        setShowSources(false);
                    }} style={{ display: 'flex' }}>
                        <Croix height={24} width={24} />
                    </span>
                    {
                        moduleActif ?
                            <div className="titre" style={{ position: 'relative', cursor: 'pointer' }}><span onClick={() => setModuleActif(null)}>Ressources</span>
                                <ChevronDroite className="chevronModule" /> <span style={{ marginLeft: '25px', fontWeight: 'bold', fontSize: 'inherit' }}>{moduleActif}</span>
                            </div>

                            :
                            <div className="titre" style={{ position: 'relative' }}>Ressources</div>

                    }

                </div>
                <div>
                    <></>
                </div>
            </div>
        </div>
        <div className="groupeAddModule">
            {moduleActif === "QCM" ?
  <ModuleQCM_eleve
  cours={cours}
  setModuleActif={setModuleActif}
  setQuestionsQCM={setQuestionsQCM}
  setShowModules={setShowModules}
/>
            :
            <>
                {modules
                    .filter((module) => module.isVisible)
                    .map((module, index) => (
                        <OptionModule
                        img={module.icon}
                        index={index}
                        titre={module.title}
                        opened={index === currentOpened}
                        description={module.description_eleve}
                        statut={module.statut}/>
                    ))}
            </>
}
        </div>
    </>
    );
};

export default SeeModules;
