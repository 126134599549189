import './LoadingCircle.css';

const LoadingCircle = ({taille, marginLeft = 0}) => {
    return (
            <div className="loading-container" style={{width:`${taille}px`, height:`${taille}px`}}>
                <div className="loading-spinner" style={{width:`${taille}px`, height:`${taille}px`, marginLeft:`${marginLeft}px`, border:`${Math.round(taille / 7)}px solid #e0e0e0`, borderTop:`${Math.round(taille / 7)}px solid #195da9`}}></div>
            </div>
    )
}

export default LoadingCircle;