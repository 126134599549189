// MenuChatbotsProfs.js

import React, { useState, useRef, useCallback, useMemo } from "react";
import "./ListAssistants.css";
import LoadingCircle from "./LoadingCircle";
import { serverPHP } from "../consts";
import RenameModal from "./RenameModal";
import AddChatbotModal from "./AddChatBotModal";
import { useNavigate } from 'react-router';
import AssistantCard from './AssistantCard';
import RemoveChatbotModal from "./RemoveChatbotModal";

const MenuChatbotsProfs = ({
    userInfo,
    setCours,
    setGroupes,
    setUserInfo,
    assistants,
    setAssistants,
    groupes,
    setRefresh,
    loaded,
    showModules
}) => {
    const assistantButtonRefs = useRef({});
    const [openMenuAssistant, setOpenMenuAssistant] = useState(null);
    const [creatingAssistant, setCreatingAssistant] = useState(false);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [renameCourseTitle, setRenameCourseTitle] = useState('');
    const [currentCourseIdForRename, setCurrentCourseIdForRename] = useState(null);
    const [currentCourseIdForAddGroupe, setCurrentCourseIdForAddGroupe] = useState(null);
    const [currentCourseIdForRemoveGroupe, setCurrentCourseIdForRemoveGroupe] = useState(null);
    const [showAddGroupModal, setShowAddGroupModal] = useState(false);
    const [showRemoveGroupModal, setShowRemoveGroupModal] = useState(false);

    const navigate = useNavigate();

    const toggleMenuAssistant = useCallback((assistantId) => {
        setOpenMenuAssistant((prevOpenMenu) => (prevOpenMenu === assistantId ? null : assistantId));
    }, []);

    const handleOpenRenameModal = useCallback((assistant) => {
        setRenameCourseTitle(assistant.sujet);
        setCurrentCourseIdForRename(assistant.id);
        setShowRenameModal(true);
    }, []);

    const handleOpenAddGroup = useCallback((assistant) => {
        setCurrentCourseIdForAddGroupe(assistant.id);
        setShowAddGroupModal(true);
    }, []);

    const handleOpenRemoveGroup = useCallback((assistant) => {
        setCurrentCourseIdForRemoveGroupe(assistant.id);
        setShowRemoveGroupModal(true);
    }, []);

    const handleAddChatbot = useCallback(async (assistantId, groupId, date) => {
        try {
            const response = await fetch(`${serverPHP}/db/addChatbotToGroup.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ assistant_id: assistantId, group_id: groupId, date: date.toISOString().split('T')[0] }),
            });

            const data = await response.json();
            if (data.success) {
                setGroupes(prevGroupes =>
                    prevGroupes.map(groupe =>
                        groupe.groupe_id === groupId
                            ? {
                                ...groupe,
                                associations_groupe_cours: [...groupe.associations_groupe_cours, { assistant_id: assistantId, date }]
                            }
                            : groupe
                    )
                );
            } else {
                throw new Error("Erreur lors de l'ajout du chatbot.");
            }
        } catch (error) {
            console.error("Erreur lors de l'ajout du chatbot :", error);
        } finally {
            setRefresh(true);
        }
    }, [setGroupes, setRefresh]);

    const handleRemoveChatbot = useCallback(async (assistantId, groupId) => {
        try {
            const response = await fetch(`${serverPHP}/db/removeChatbotFromGroup.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    assistant_id: assistantId,
                    group_id: groupId,
                }),
            });

            const data = await response.json();
            if (data.success) {
                setGroupes((prevGroupes) =>
                    prevGroupes.map((groupe) => {
                        if (groupe.groupe_id === groupId) {
                            return {
                                ...groupe,
                                associations_groupe_cours: groupe.associations_groupe_cours.filter(
                                    (assoc) => assoc.assistant_id !== assistantId
                                ),
                            };
                        }
                        return groupe;
                    })
                );
            } else {
                throw new Error("Erreur lors de la suppression du chatbot.");
            }
        } catch (error) {
            console.error("Erreur lors de la suppression du chatbot :", error);
        } finally {
            setRefresh(true);
        }
    }, [setGroupes, setRefresh]);

    const handleModifyCourseTitle = useCallback(async (newTitle) => {
        try {
            const response = await fetch(`${serverPHP}/db/modifyTitreCours.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ cours_id: currentCourseIdForRename, titre: newTitle }),
            });

            const data = await response.json();
            if (!data.success) {
                console.error("Erreur lors de la modification du titre du cours :", data.message || 'Unknown error');
            } else {
                setAssistants(prevAssistants =>
                    prevAssistants.map(assistant =>
                        assistant.id === currentCourseIdForRename ? { ...assistant, sujet: newTitle } : assistant
                    )
                );
            }
        } catch (error) {
            console.error("Erreur lors de la modification du titre du cours :", error);
        }
    }, [currentCourseIdForRename, setAssistants]);

    const renderInfoMessage = useMemo(() => {
        if (assistants && groupes) {
            const hasChatbots = assistants.length > 0;
            const hasGroups = groupes.length > 0;
            const groupsWithChatbots = groupes.filter(groupe => groupe.associations_groupe_cours.length > 0);
            const groupsWithEleves = groupes.filter(groupe => groupe.inscriptions_eleves_groupes.length > 0);

            if (!hasChatbots && !hasGroups)
                return <div className="info-message">D'abord, créez un nouveau chatbot en cliquant sur le "+" ci-dessous.</div>;
            if (hasChatbots && !hasGroups)
                return <div className="info-message">Maintenant, créez un nouveau groupe en cliquant sur le bouton "plus" à gauche.</div>;
            if (hasChatbots && hasGroups && groupsWithChatbots.length === 0)
                return <div className="info-message">Vous pouvez maintenant déposer vos chatbots (ci-dessous) dans votre groupe pour les y ajouter.</div>;
            if (hasChatbots && hasGroups && groupsWithChatbots.length > 0 && groupsWithEleves.length === 0)
                return <div className="info-message">Diffusez le groupe à vos élèves en cliquant sur son nom dans le menu à gauche, puis sur "Partager".</div>;
        }
        return null;
    }, [assistants, groupes]);

    const handleCreationAssistant = useCallback(async () => {
        setCreatingAssistant(true);
        const newCours = {
            date: new Date(),
            sujet: "Nouveau cours",
            instructions: userInfo.instructions_default,
            accroche: userInfo.accroche_default,
            files: [],
            auteur: userInfo.id,
            modules: { partage: [], qcm: [], cartementale: [], audio: [], diapo: [] },
            suggestions: []
        };

        try {
            const response = await fetch(`${serverPHP}/createAssistantAllInOne.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(newCours),
            });

            const data = await response.json();
            if (data.error) throw new Error(data.error);

            newCours.assistant_id = data.assistant_id;
            newCours.vector_store = data.vector_store;
            newCours.id = data.id;

            setAssistants(prevAssistants => [...prevAssistants, newCours]);
            navigate("/chatbot/" + newCours.id);
        } catch (error) {
            console.error("Erreur lors de la création du cours :", error);
        } finally {
            setCreatingAssistant(false);
        }
    }, [setAssistants, navigate, userInfo]);

    const handleDeleteAssistant = useCallback(async (assistantId) => {
        try {
            const response = await fetch(`${serverPHP}/db/deleteAssistant.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id: assistantId }),
            });

            const data = await response.json();
            if (data.error) throw new Error(data.error);

            if (data.ok) {
                setAssistants(prevAssistants => prevAssistants.filter(assistant => assistant.id !== assistantId));
                setRefresh(true);
            } else {
                throw new Error("Erreur lors de la suppression de l'assistant.");
            }
        } catch (error) {
            console.error("Erreur lors de la suppression de l'assistant :", error);
            setRefresh(true);
        }
    }, [setAssistants, setRefresh]);

    if (!loaded) return null;

    return (
        <>
            {renderInfoMessage}
            <div className="listeChatBots">
                <div className="assistants-container">
                    {assistants?.sort((a, b) => new Date(b.date) - new Date(a.date)).map((assistant, index) => {
                        if (!assistantButtonRefs.current[assistant.id]) {
                            assistantButtonRefs.current[assistant.id] = React.createRef();
                        }

                        return (
                            <AssistantCard
                                key={assistant.id}
                                assistant={assistant}
                                index={index}
                                navigate={navigate}
                                assistantButtonRefs={assistantButtonRefs}
                                toggleMenuAssistant={toggleMenuAssistant}
                                openMenuAssistant={openMenuAssistant}
                                setOpenMenuAssistant={setOpenMenuAssistant}
                                handleOpenAddGroup={handleOpenAddGroup}
                                handleOpenRemoveGroup={handleOpenRemoveGroup}
                                handleOpenRenameModal={handleOpenRenameModal}
                                handleDeleteAssistant={handleDeleteAssistant}
                                groupes={groupes}
                                showModules={showModules}
                            />
                        );
                    })}

                    <div
                        className="assistant-card new-assistant fade-in"
                        onClick={handleCreationAssistant}
                        style={{
                            animationDuration: '0.1s',
                            animationDelay: `${assistants.length * 0.05}s`
                        }}
                    >
                        <div className="new-assistant-content">
                            {creatingAssistant ? (
                                <LoadingCircle taille={70} />
                            ) : (
                                <>
                                    <div className="plus-sign">+</div>
                                    <div className="assistant-title">
                                        Nouveau
                                        <br />
                                        chatbot
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {showRenameModal && (
                    <RenameModal
                        message="Nouveau titre du cours :"
                        var={renameCourseTitle}
                        setVar={newTitle =>
                            setAssistants(prevAssistants =>
                                prevAssistants.map(assistant =>
                                    assistant.id === currentCourseIdForRename ? { ...assistant, sujet: newTitle } : assistant
                                )
                            )
                        }
                        onClose={() => setShowRenameModal(false)}
                        onModify={handleModifyCourseTitle}
                    />
                )}
                {showAddGroupModal && (() => {
                    const existingGroupIds = groupes
                        .filter(g =>
                            g.associations_groupe_cours.some(assoc => assoc.assistant_id === currentCourseIdForAddGroupe)
                        )
                        .map(g => g.groupe_id);

                    const availableGroups = groupes
                        .filter(groupe => !existingGroupIds.includes(groupe.groupe_id))
                        .map(groupe => ({
                            ...groupe,
                            id: groupe.groupe_id,
                            sujet: groupe.nom,
                            color: groupe.color,
                        }));

                    return (
                        <AddChatbotModal
                            onClose={() => setShowAddGroupModal(false)}
                            assistantId={currentCourseIdForAddGroupe}
                            handleAddChatbot={handleAddChatbot}
                            addChatBotToGroup={true}
                            availableItems={availableGroups}
                        />
                    );
                })()}
                {showRemoveGroupModal && (() => {
                    const existingGroups = groupes.filter(groupe =>
                        groupe.associations_groupe_cours.some(assoc => assoc.assistant_id === currentCourseIdForRemoveGroupe)
                    ).map(groupe => ({
                        id: groupe.groupe_id,
                        sujet: groupe.nom,
                        color: groupe.color,
                    }));

                    return (
                        <RemoveChatbotModal
                            onClose={() => setShowRemoveGroupModal(false)}
                            assistantId={currentCourseIdForRemoveGroupe}
                            handleRemoveChatbot={handleRemoveChatbot}
                            removeChatBotFromGroupe={true}
                            existingTargets={existingGroups}
                        />
                    );
                })()}
            </div>
        </>
    );
};

export default MenuChatbotsProfs;
