// Modal.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onClose, initialContent }) => {
  const [localContent, setLocalContent] = useState(initialContent);
  const modalRef = useRef(null);

  useEffect(() => {
    setLocalContent(initialContent);
  }, [initialContent]);

  const handleClickOutside = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(localContent);
      }
    },
    [onClose, localContent]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <textarea
          value={localContent}
          onChange={(e) => setLocalContent(e.target.value)}
          className="modal-textarea"
          style={{ width: '100%', height: '355px' }}
        />
        <button onClick={() => onClose(localContent)} className="login-button" style={{ marginTop: '10px' }}>
          Fermer
        </button>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
