import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { serverPHP } from '../consts';

const ConfirmInscription = ({ id, setError, setInfo }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Effectuer un fetch pour confirmer l'inscription
    fetch(serverPHP + '/db/confirmInscription.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setInfo(
            <>
              <p>Votre inscription vient d'être <strong>validée</strong>.</p>
              <p>
                Vous pouvez maintenant vous connecter avec votre adresse mail et le mot de passe que vous avez renseigné.
              </p>
            </>
          );
        } else {
          setError(data.error || 'Une erreur inconnue est survenue.');
        }
      })
      .catch((error) => {
        setError('Erreur lors de la confirmation de l\'inscription.');
      })
      .finally(() => {
        // Rediriger l'utilisateur après le traitement
        navigate('/');
      });
  }, [id, setError, setInfo, navigate]);

  return null;
};

export default ConfirmInscription;
