import { useState } from 'react';

import SourcesMenu from "./SourcesMenu";
import Header from "./Header";
import Chatbot from "../chatbot";
import AddModule from './AddModule';

const SetAssistant = ({ cours, setCours, setUserInfo, userInfo, updateAssistant, suggestions }) => {
    const [showModules, setShowModules] = useState(false)
    const [moduleActif, setModuleActif] = useState(null);
    const [showSources, setShowSources] = useState(false); // il  faudra faire quelque chose de showSources



    return (
        <>
            <SourcesMenu
                cours={cours}
                setCours={setCours}
                setShowModules={setShowModules}
                showModules={showModules}
                updateAssistant={updateAssistant}
                setModuleActif={setModuleActif}
                showSources={showSources}
                setShowSources={setShowSources}
            />
            <div className="center-section" style={{ overflow: "hidden" }}>
                <Header cours={cours} setCours={setCours} userInfo={userInfo} setUserInfo={setUserInfo} updateAssistant={updateAssistant} />
                    <Chatbot
                        assistant={cours ? cours.assistant_id : null}
                        accroche={cours ? cours.accroche : null}
                        showModules={showModules}
                        setShowModules={setShowModules}
                        ModulesComponent={AddModule}
                        modulesComponentProps={{ cours, setCours, userInfo, moduleActif, setModuleActif, setShowModules, showSources, setShowSources }}
                        showSources={showSources}
                        setShowSources={setShowSources}
                        suggestions={suggestions}
            />
            </div>
        </>
    )
}

export default SetAssistant;