import { useState, useEffect } from 'react';
import { ReactComponent as PicsImagefile } from "../pics/imagefile.svg";

import ModuleDiapo_ShowDiapo from '../composants/Modules/ModuleDiapo_ShowDiapo';

const ModuleDiapoEleve = ({cours}) => {
    const [currentDiapo, setCurrentDiapo] = useState(null);

    return (<>
        <div className="containerFichiersPartage">
            {cours.modules.diapo.map((file, index) => (
                <div
                    key={index}
                    className="partageItem partageHover"
                    style={{ justifyContent: "space-between", cursor: 'pointer' }}
                    onClick={() => setCurrentDiapo(file)}
                >
                    <div>
                        <div className="actionButton">
                            <PicsImagefile />
                        </div>
                        <span>{file.nomFichier}</span>
                    </div>
                </div>
            ))}

            {cours.modules.diapo.length === 0 &&
                <div style={{ width: '100%' }}><div className="containerFichiersPartage"></div>
                    <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
                        Vous n'avez encore généré aucun diaporama.</p></div>
            }
        </div>
        {currentDiapo &&
                        <ModuleDiapo_ShowDiapo
                            currentDiapo={currentDiapo}
                            setCurrentDiapo={setCurrentDiapo}
                            canModify={false}
                        />
                    }
    </>)
}

export default ModuleDiapoEleve;