import {useEffect, useState} from 'react';
import Module_selectFile_1 from "./Module_selectFile_1";

const Module_selectFile = ({cours, reset, navigationSelectFile, setNavigationSelectFile, setReset, setTitreMenu, setContenuFichier, titreSelection = <><strong>Générer</strong> des questions</> }) => {


    const sources = cours.files.filter((d) => d.statut === 2);


return (<>
{navigationSelectFile === null ?
    <>
        <div className="moduleContainer" style={{ justifyContent: "center" }}>
            <div className="moduleTitre">{titreSelection}</div>
            {sources.length > 0 ? (
                <button className="login-button ml20" onClick={() => setNavigationSelectFile(true)}>
                    D'après une source existante
                </button>
            ) : (
                <button className="disabled-button ml20" disabled>
                    Aucune source ajoutée
                </button>
            )}
            <button className="login-button ml20" onClick={() => setNavigationSelectFile(false)}>
                Importer un nouveau fichier
            </button>
        </div>

    </> :

    <Module_selectFile_1
        cours={cours}
        sources={sources}
        setTitreMenu={setTitreMenu}
        setContenuFichier={setContenuFichier}
        reset={reset}
        fichierFromSources={navigationSelectFile}
        setFichierFromSources={setNavigationSelectFile}
        setReset={setReset}
    />
}
</>)
}

export default Module_selectFile;