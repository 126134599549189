import { useCallback } from "react";
import { sendMessageToApi } from "./apiHandler";

export const useChatHandlers = ({
  messages, // L'ensemble des messages du philosophe à afficher
  assistant, // L'ID de l'assistant
  setMessages, // Fonction pour définir l'ensemble des messages
  stopAll,
  setStopAll,
  setThinking, // Fonction pour définir si on est en train de réfléchir ou non (pour l'affichage du bouton "stop" par exemple)
  setGenerating, // Fonction pour définir si on est en train de générer ou non
  setReponsesEnCours, // Fonction : indique si on a appuyé sur le bouton et qu'on attend la fin de la génération
  prePrompt
}) => {
  const handleSendMessage = useCallback(
    async (message) => {
      if (stopAll) {
        setReponsesEnCours(false);
        stopAll(); // Annule le traitement en cours
      }

      const controller = new AbortController();
      const signal = controller.signal;

      const stopAllFunc = () => {
        controller.abort();
        setReponsesEnCours(false);
        setThinking(false);
        setGenerating(false);

        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const lastMessage = updatedMessages[updatedMessages.length - 1];

          if (lastMessage && lastMessage.content.trim() === "") {
            // Supprime le dernier message s'il est vide après l'avoir coupé
            updatedMessages.pop();
          } else if (lastMessage && lastMessage.content.endsWith("▮")) {
            // Supprime le caractère '▮' s'il est le dernier caractère
            lastMessage.content = lastMessage.content.slice(0, -1);
          }

          return updatedMessages;
        });
      };

      setStopAll(() => stopAllFunc); // Définit la fonction pour arrêter tous les processus

      const filterMessageKeys = (messages) => {
        return messages
          .filter((msg) => msg.role === "user" || msg.role === "assistant")
          .map((msg) => ({
            content: msg.content,
            date: msg.date,
            role: msg.role,
          }));
      };

      const sendMessageWithUpdates = async (currentMessages) => {
        const filteredMessages = filterMessageKeys(currentMessages).slice(-8);

        // Attendre la réponse avec sources
        await sendMessageToApi(
          filteredMessages,
          (partialResponse) => {
            if (signal.aborted) return;

            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];

              // Gestion des nouveaux messages
              if (partialResponse.event === "newMessage") {
                // Ajouter un nouveau message assistant
                updatedMessages.push(partialResponse.newAssistantMessage);
              } else if (partialResponse.event === "updateSource") {
                const lastMessage = updatedMessages[updatedMessages.length - 1];
                if (lastMessage && lastMessage.role === "assistant") {
                  lastMessage.sources = partialResponse.source;
                }
              } else {
                // Mettre à jour le dernier message assistant
                const lastMessage = updatedMessages[updatedMessages.length - 1];
                if (lastMessage && lastMessage.role === "assistant") {
                  lastMessage.content = partialResponse;
                }
              }

              return updatedMessages;
            });
          },
          setThinking,
          setGenerating,
          assistant,
          signal,
          prePrompt
        );

        setReponsesEnCours(false);
      };

      // Envoi du message de l'utilisateur une seule fois
      const newMessages = [
        ...messages,
        { role: "user", content: message, date: new Date().toISOString() },
      ];
      setMessages(newMessages);
      setReponsesEnCours(true);

      await sendMessageWithUpdates(newMessages);
    },
    [
      messages,
      assistant,
      setMessages,
      stopAll,
      setThinking,
      setGenerating,
      setStopAll,
      setReponsesEnCours,
      prePrompt
    ]
  );

  return { handleSendMessage };
};