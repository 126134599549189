import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import "./Chatbox.css";
import MessageInput from './MessageInput';
import { debounce } from 'lodash';
import MessageList from './MessageList';
import { useChatHandlers } from './UseChatHandlers';
import { ReactComponent as Chevron } from "./images/chevron.svg";


const ChatBox = ({
    assistant,
    setShowSources,
    currentSources,
    setCurrentSources,
    setCurrentNote,
    accroche, 
    ComposantInputBar,
    prePrompt,
    setShowModules,
    suggestions,
    questionsQCM=[]
}) => {

    const [inputUser, setInputUser] = useState("");

    const [messages, setMessages] = useState([]);
    const [thinking, setThinking] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [stopAll, setStopAll] = useState(null);
    const [showChevron, setShowChevron] = useState(false);
    const cadreMessagesRef = useRef(null);  // Référence pour cadreMessages
    const cadreHeightRef = useRef(null);  // Stocker la hauteur


    const messagesRef = useRef(null);
    const isNearBottomRef = useRef(true);
    const [reponsesEnCours, setReponsesEnCours] = useState(false);
    const prevScrollTopRef = useRef(0);

    const scrollDebut = useRef(0);

    const [stopScroll, setStopScroll] = useState(false);
    const [firstAssistantTop, setFirstAssistantTop] = useState(null); // État pour stocker la valeur top


    // Utiliser useChatHandlers pour gérer l'envoi de messages
    const { handleSendMessage } = useChatHandlers({
        messages,
        assistant,
        setMessages,
        stopAll,
        setStopAll,
        //stopGen,
        setThinking,
        setGenerating,
        setReponsesEnCours,
        prePrompt
    });


    const updateCadreHeight = useCallback(() => {
        if (cadreMessagesRef.current) {
            cadreHeightRef.current = cadreMessagesRef.current.clientHeight;  // Met à jour la hauteur
        }
    }, []);


    useEffect(() => {
        if (accroche) {
            const formattedAccroche = accroche.replace(/\n/g, '\n\n'); // Remplace chaque \n par \n\n
            setMessages([{
                content: formattedAccroche,
                date: new Date().toISOString(),
                role: "assistant"
            }]);
        }
    }, [accroche]);
    

    useEffect(() => {
        const messagesDiv = messagesRef.current;
        if (messagesDiv) {
            const isUserNearBottom = // on en a besoin pour le chevron
                messagesDiv.scrollHeight - messagesDiv.scrollTop <= messagesDiv.clientHeight + 50;
            isNearBottomRef.current = isUserNearBottom;
            setShowChevron(!isUserNearBottom);
        }
    }, [messages]);

    useEffect(() => {
        // Récupérer la hauteur initiale de cadreMessages
        updateCadreHeight();

        // Ajoute un listener pour détecter le redimensionnement de la fenêtre
        window.addEventListener('resize', updateCadreHeight);

        // Nettoyage du listener lors du démontage du composant
        return () => {
            window.removeEventListener('resize', updateCadreHeight);
        };
    }, [updateCadreHeight]);  // Le useEffect dépend de la fonction updateCadreHeight


    useEffect(() => { //quand on commence une génération, on n'a pas de raison d'arrêter le scrolling
        if (reponsesEnCours) setStopScroll(false);
    }, [reponsesEnCours])

    const scrollToBottom = useCallback(() => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, []);


    const hardScrollToBottom = useCallback(() => {
        if (messagesRef.current) {
            //console.log("hardScrollToBottom");
            const targetPosition = messagesRef.current.scrollHeight;
            messagesRef.current.scrollTo({
                top: targetPosition,
                behavior: 'instant'
            });
        }
    }, []);

    useEffect(() => {
        const largeInputContainer = document.querySelector('.large-input-container');
        const messageInputContainer = document.querySelector('.message-input-container');

        if (largeInputContainer && messageInputContainer) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const containerWidth = entry.contentRect.width;

                    if (containerWidth < 1200) {
                        messageInputContainer.classList.add('small-message-input');
                        messageInputContainer.classList.remove('large-message-input');
                    } else {
                        messageInputContainer.classList.add('large-message-input');
                        messageInputContainer.classList.remove('small-message-input');
                    }
                }
            });

            resizeObserver.observe(largeInputContainer);

            // Cleanup on unmount
            return () => {
                if (largeInputContainer) {
                    resizeObserver.unobserve(largeInputContainer);
                }
            };
        }
    }, []);
    
    


    const handleScroll = useCallback(
        debounce(() => {
            const messagesDiv = messagesRef.current;

            if (messagesDiv) {
                const currentScrollTop = messagesDiv.scrollTop;

                // Si l'utilisateur a fait remonter le scroll (position actuelle plus haute que la précédente)
                if (currentScrollTop < prevScrollTopRef.current) {
                    setStopScroll(true);
                }

                // Met à jour la position précédente
                prevScrollTopRef.current = currentScrollTop;

                const isUserNearBottom =
                    messagesDiv.scrollHeight - messagesDiv.scrollTop <= messagesDiv.clientHeight + 50;
                isNearBottomRef.current = isUserNearBottom;
                setShowChevron(!isUserNearBottom);
            }
        }, 200),
        [messagesRef, prevScrollTopRef, isNearBottomRef, setShowChevron]
    );


    useEffect(() => {

        if (!stopScroll) {
            const messagesDiv = messagesRef.current;
            if (messagesDiv) {
                const currentScrollTop = messagesDiv.scrollTop;
                if (currentScrollTop + 20 >= firstAssistantTop) {
                    setStopScroll(true);
                    messagesDiv.scrollTop = firstAssistantTop;
                } else {
                    hardScrollToBottom();

                }
            }

        }

    }, [messages, hardScrollToBottom, firstAssistantTop, stopScroll]);



    useEffect(() => {
        // On scrolle en bas dès qu'on commence la génération
        if (reponsesEnCours) {
            //console.log("on scrolle en bas");
            if (messagesRef.current) scrollDebut.current = messagesRef.current.scrollTop;
            hardScrollToBottom();

        }
    }, [reponsesEnCours, hardScrollToBottom]);

    // Mémorisation de la liste des messages pour éviter les re-rendus inutiles
    const renderedMessages = useMemo(() => (
        <MessageList
            messages={messages}
            assistant={assistant}
            thinking={thinking}
            setFirstAssistantTop={setFirstAssistantTop}
            reponsesEnCours={reponsesEnCours}
            setShowSources={setShowSources}
            currentSources={currentSources}
            setCurrentSources={setCurrentSources}
            setCurrentNote={setCurrentNote}
            setShowModules={setShowModules}
        />
    ), [messages, assistant, thinking, reponsesEnCours]);



    return (

        <>            <div className="cadreMessages" ref={cadreMessagesRef} style={{ position: 'relative', overflowY: 'hidden' }}>
            <div className="messages" ref={messagesRef} onScroll={handleScroll} style={{ overflowY: 'auto', overflowX:'hidden',maxHeight: '100%' }}>
                <div style={{ maxWidth: '1130px', width: '100%' }}>
                    {renderedMessages}
                    <div style={{ height: '20px', flexShrink: 0 }} />
                </div>
            </div>
            <div className={`chevronScroll ${showChevron ? 'chevronVisible' : 'chevronHidden'}`} onClick={scrollToBottom}>
                <Chevron height={30} width={30}/>

            </div>
        </div>
            <div className="large-input-container">
                <div className="message-input-container">
                    <MessageInput
                        onSendMessage={handleSendMessage}
                        thinking={thinking}
                        generating={generating}
                        stopAll={stopAll}
                        inputUser={inputUser}
                        setInputUser={setInputUser}
                        ComposantInputBar={ComposantInputBar}
                        suggestions={suggestions}
                        questionsQCM={questionsQCM}
                    />
                </div>
            </div>
        </>
    )

}

export default ChatBox;