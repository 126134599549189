// Message.js

import React, { forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkDirective from 'remark-directive';
import { visit } from 'unist-util-visit';
import './Message.css';
import LoadingDots from './LoadingDots';
import { ReactComponent as LeftTail } from "./images/leftTail.svg";
import { ReactComponent as RightTail } from "./images/rightTail.svg";


const Message = forwardRef(({
  msg,
  thinking,
  setShowSources,
  currentSources,
  setCurrentSources,
  setCurrentNote,
  setShowModules
}, ref) => {

  const showSources = (X) => {
    setCurrentNote(X);
    setShowModules(false);
    setCurrentSources(msg.sources);
    setShowSources(true);
    console.log(msg);
    console.log("click", X);
  };

  const isMessageFinished = !msg.content?.trim().endsWith('▮');

  // Plugin Remark personnalisé pour traiter les séquences spéciales
  const remarkCustomSource = () => (tree) => {
    visit(tree, 'text', (node, index, parent) => {
      const { value } = node;
      const regex = /【\d+:(\d+)†([^】]*)】?/g;
      let lastIndex = 0;
      const newNodes = [];
  
      while (lastIndex < value.length) {
        regex.lastIndex = lastIndex;
        let match = regex.exec(value);
  
        if (match && match.index === lastIndex) {
          const [fullMatch, X, source] = match;
  
          newNodes.push({
            type: 'link',
            url: `#${X}`,
            title: null,
            children: [{ type: 'text', value: (parseInt(X) + 1).toString() }],
            data: {
              hProperties: {
                className: 'source-link',
              },
            },
          });
  
          lastIndex += fullMatch.length;
        } else {
          // Move one character forward to re-attempt matching in the next iteration
          newNodes.push({ type: 'text', value: value[lastIndex] });
          lastIndex += 1;
        }
      }
  
      if (newNodes.length > 0) {
        parent.children.splice(index, 1, ...newNodes);
      }
    });
  };

  return (
    <div className={`message-container big${msg.role}`} >
      <div ref={ref} className={`message msg${msg.role}`}>
        <div className={msg.role}>
          {msg.role === 'assistant' && (
            <>
              <LeftTail height={13} width={8} className="cochegauche"/>
              <div className="nomPhilosophe">
                {msg.name}
              </div>
            </>
          )}
          {msg.content?.trim() === '' || msg.content == null ? (
            thinking ? <LoadingDots /> : null
          ) : (
            <ReactMarkdown
              remarkPlugins={[remarkDirective, remarkCustomSource]}
              components={{
                a: ({ node, children, ...props }) => {
                  if (props.href.startsWith('#')) {
                    // Lien de source
                    const X = props.href.slice(1);
                    return (
                      <span
                        className="source-link"
                        style={{
                          opacity: isMessageFinished ? 1 : 0.3,
                          pointerEvents: isMessageFinished ? 'auto' : 'none',
                        }}
                        onClick={() => showSources(X)}
                      >
                        {children}
                      </span>
                    );
                  } else {
                    // Lien web normal
                    return (
                      <a
                        className="weblink"
                        href={props.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {children}
                      </a>
                    );
                  }
                },
              }}
            >
              {msg.content}
            </ReactMarkdown>
          )}
          {msg.role === 'user' && <RightTail height={13} width={8} className="cochedroite"/>}
        </div>
      </div>
    </div>
  );
});

export default React.memo(Message);