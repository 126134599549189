// AddChatbotModal.js

import React, { useState, useEffect, useRef } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import './AddChatBotModal.css';
import { fr } from "react-day-picker/locale";

const AddChatbotModal = ({
    onClose,
    handleAddChatbot,
    addChatBotToGroup = false,
    availableItems,
    assistantId = null,
    groupId = null
}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const modalRef = useRef();

    const label = addChatBotToGroup ? "groupe" : "chatbot";

    const paginatedItems = availableItems.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    const totalPages = Math.ceil(availableItems.length / itemsPerPage);

    const toggleSelectedItem = (item) => {
        setSelectedItems(prevSelectedItems => {
            if (prevSelectedItems.some(selected => selected.id === item.id)) {
                // Remove the item if it's already selected
                return prevSelectedItems.filter(selected => selected.id !== item.id);
            } else {
                // Add the item if it's not selected
                return [...prevSelectedItems, item];
            }
        });
    };

    const handleSubmit = () => {
        if (selectedItems.length > 0) {
            if (!addChatBotToGroup) {
                selectedItems.forEach(item => {
                    handleAddChatbot(item.id, groupId, selectedDate);
                });
            } else {
                selectedItems.forEach(item => {
                    handleAddChatbot(assistantId, item.id, selectedDate);
                });
            }
        }
        onClose();
    };

    // Close modal when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="modal-overlay-login">
            <div className="modal-content-access" ref={modalRef}>
                <div className="modal-body">
                    {/* Main div divided into two columns */}
                    <div className="modal-left">
                        <div className="chatbot-list">
                            {paginatedItems.length === 0 && <span style={{ fontStyle: "italic" }}>Il n'y a plus de nouveau {label} !</span>}
                            {paginatedItems.map((item) => (
                                <div
                                    key={item.id}
                                    className={`chatbot-item ${selectedItems.some(selected => selected.id === item.id) ? 'selected' : ''}`}
                                    onClick={() => toggleSelectedItem(item)}
                                >
                                    {item.color &&
                                        <div
                                            className="iconeGroupe"
                                            style={{ backgroundColor: item.color, marginRight: 0 }}
                                        />
                                    }
                                    <span className="chatbot-sujet">
                                        {item.sujet || item.nom}
                                    </span>
                                </div>
                            ))}
                        </div>
                        {totalPages > 1 && (
                            <div className="pagination">
                                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                    <button
                                        key={page}
                                        onClick={() => setCurrentPage(page)}
                                        className={page === currentPage ? 'active' : ''}
                                    >
                                        {page}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="modal-right">
                        {/* Display default message or DayPicker */}
                        {!showDatePicker ? (
                            <div className="default-date-message" onClick={() => setShowDatePicker(true)}>
                                <p style={{ marginTop: '0' }}><strong>Veuillez choisir les {label}s à ajouter.</strong></p>
                                <p>Par défaut, votre chatbot est inscrit <strong>à la date d'aujourd'hui</strong> dans votre groupe. Vous pouvez cependant choisir une date différente en cliquant ici.</p>
                                <p style={{ marginBottom: '0' }}>Si vous choisissez une date <strong>postérieure</strong> à la date du jour, c'est à cette date que votre
                                    chatbot sera mis à la disposition de vos élèves.</p>
                            </div>
                        ) : (
                            <DayPicker
                                mode="single"
                                selected={selectedDate}
                                onSelect={setSelectedDate}
                                locale={fr}
                            />
                        )}
                    </div>
                </div>
                <div className="modal-buttons">
                    <button
                        onClick={handleSubmit}
                        disabled={selectedItems.length === 0}
                        className={`login-button ${selectedItems.length === 0 ? 'disabled' : ''}`}
                        style={{width:'180px'}}
                    >
                        {selectedItems.length === 0 ? <>Aucune sélection</> :
                        <>
                        Ajouter {selectedItems.length} {selectedItems.length > 1 ? label + 's' : label}</>
}
                    </button>
                    <button className="signup-button" onClick={onClose} style={{width:'180px'}}>
                        Annuler
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddChatbotModal;
