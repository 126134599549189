// ListeMenuProfs.js

import React, {useState} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import GroupesMenu from "./GroupesMenu";
import HeaderMainMenuProf from "./HeaderMainMenuProf";
import MenuChatbotsProfs from "./MenuChatbotsProfs";

const ListeMenuProfs = ({ 
    userInfo, 
    setCours, 
    setUserInfo, 
    groupes, 
    setGroupes, 
    assistants, 
    setRefresh, 
    loaded, 
    setAssistants, 
    matieres,
    showModules,
    setShowModules
}) => {


    return (
        <DndProvider backend={HTML5Backend}>
            <GroupesMenu
                setCours={setCours}
                groupes={groupes}
                setGroupes={setGroupes}
                assistants={assistants}
                setRefresh={setRefresh}
                userInfo={userInfo}
                loaded={loaded}
                matieres={matieres}
            />

            <div className="center-section" style={{ backgroundColor: 'var(--dark-bg-color)' }}>
                <HeaderMainMenuProf
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                matieres={matieres}
                setShowModules={setShowModules}
                showModules={showModules}
                />
                <MenuChatbotsProfs
                    userInfo={userInfo}
                    setCours={setCours}
                    setGroupes={setGroupes}
                    setUserInfo={setUserInfo}
                    assistants={assistants}
                    setAssistants={setAssistants}
                    groupes={groupes}
                    setRefresh={setRefresh}
                    loaded={loaded}
                    showModules={showModules}
                />
            </div>
        </DndProvider>
    );
};

export default ListeMenuProfs;
