import { useState, useEffect } from 'react';

import Module_selectFile_2 from "./Module_selectFile_2";

const Module_selectFile_1 = ({ cours, sources, fichierFromSources, setFichierFromSources, setTitreMenu, reset, setReset, setContenuFichier }) => {
    
    return (
        <>
        <Module_selectFile_2
            cours={cours}
            setTitreMenu={setTitreMenu}
            setContenuFichier={setContenuFichier}
            reset={reset}
            fichierFromSources={fichierFromSources}
            setFichierFromSources={setFichierFromSources}
            sources={sources}
        />
        </>
    )

}

export default Module_selectFile_1;