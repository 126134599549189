import React, { useEffect, useState, useMemo } from "react";
import "./ListAssistants.css";
import { serverPHP } from "../consts";
import ListeMenuEleve from "./ListeMenuEleve";
import SeeAssistant from "./SeeAssistant";
import ComposantInputBar from "./ComposantInputBar";

const MainMenuEleve = ({ userInfo, setUserInfo, setUpdateUser, chatbot_id, groupe_id }) => {
    const [groupes, setGroupes] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const [loading, setLoading] = useState(true);

    const currentGroupe = useMemo(() => {
        //console.log("currentGroupe", "hello")
        console.log("groupe_id", groupe_id)
        const cGroupe = groupes.find(a => a.id === groupe_id)

        console.log("currentGroupe", cGroupe)
        return cGroupe;
    }, [groupes, groupe_id]
    );

    const cours = useMemo(() => {
        if (currentGroupe) {
        console.log("chatbot_id", chatbot_id);
        console.log(currentGroupe);
        const cCours = currentGroupe.cours.find(a => a.id === chatbot_id);
        console.log ("cCours", cCours);
        return cCours;
    } else return null;
    
        //return null;
    }, [currentGroupe, chatbot_id]
    );



    useEffect(() => {
        fetchData();
    }, [userInfo.id]);

    // In MainMenuEleve.js
    const fetchData = async () => {
        try {
            const response = await fetch(serverPHP + "/db/getDataEleve.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ user_id: userInfo.id }),
            });

            const data = await response.json();

            if (data.error) {
                throw new Error(data.error);
            }

            // Process data to ensure cours.date is a Date object
            const formattedGroupes = data.groupes.map((groupe) => ({
                ...groupe,
                cours: groupe.cours.map((cours) => ({
                    ...cours,
                    date: new Date(cours.date),
                })),
            }));


            setGroupes(formattedGroupes);
            setLoading(false);

        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    useEffect(() => {
        // Faire la requête pour récupérer les assistants et les groupes
        if (refresh) {
            fetchData();
            setRefresh(false);
        }

    }, [refresh]);

    return (
        <>{/*chatbot_id : {chatbot_id} - groupe_id : {groupe_id}*/}
            {
                (cours == null || cours?.id == null) ?
                    <ListeMenuEleve
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        groupes={groupes}
                        setRefresh={setRefresh}
                        loading={loading}
                        groupe_id={groupe_id}
                        currentGroupe={currentGroupe}
                    />
                    :

                    <SeeAssistant
                        cours={cours}
                        groupe_id={groupe_id}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        ComposantInputBar={<ComposantInputBar setUpdateUser={setUpdateUser} userInfo={userInfo} setUserInfo={setUserInfo}/>}
                        suggestions={cours.suggestions}

                    />
            }
        </>
    )


};

export default MainMenuEleve;
