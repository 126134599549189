// AssistantCard.js

import React, { useRef, useMemo } from "react";
import { useDrag } from 'react-dnd';
import './AssistantCard.css';
import DropdownMenu from "../chatbot/DropDownMenu";
import { ReactComponent as PicsPoints } from "../pics/pointsverticaux.svg";
import { modules_full } from "../consts";

const AssistantCard = React.memo(({ 
    assistant, 
    index, 
    navigate, 
    assistantButtonRefs, 
    toggleMenuAssistant, 
    openMenuAssistant, 
    setOpenMenuAssistant, // Ensure this prop is received
    handleOpenAddGroup, 
    handleOpenRemoveGroup,
    handleOpenRenameModal, 
    handleDeleteAssistant, 
    groupes,
    showModules
}) => {
    const ref = useRef(null);

    const modules = modules_full(assistant);
    console.log(modules);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'ASSISTANT',
        item: { id: assistant.id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [assistant]);

    drag(ref);

    // Determine if the assistant is part of any group
    const isInAnyGroup = useMemo(() => {
        return groupes.some(groupe => 
            groupe.associations_groupe_cours.some(assoc => assoc.assistant_id === assistant.id)
        );
    }, [groupes, assistant.id]);

    // Dynamically create menu items
    const menuItems = useMemo(() => {
        const items = [
            { label: "Ajouter des groupes", onClick: () => handleOpenAddGroup(assistant) },
            { label: "Renommer", onClick: () => handleOpenRenameModal(assistant) },
            { label: "Supprimer", onClick: () => handleDeleteAssistant(assistant.id) },
        ];

        if (isInAnyGroup) {
            items.splice(1, 0, { label: "Retirer des groupes", onClick: () => handleOpenRemoveGroup(assistant) });
        }

        return items;
    }, [handleOpenAddGroup, handleOpenRemoveGroup, handleOpenRenameModal, handleDeleteAssistant, assistant, isInAnyGroup]);

    return (
        <div
            className="assistant-card existing-card fade-in"
            style={{ 
                animationDuration: '0.1s', 
                animationDelay: `${index * 0.05}s` 
            }}
            onClick={() => navigate("/chatbot/" + assistant.id)}
            ref={ref}
        >
            <div
                className="boutonMenuHaut"
                onClick={(e) => {
                    e.stopPropagation();
                    toggleMenuAssistant(assistant.id);
                }}
                ref={assistantButtonRefs.current[assistant.id]}
            >
                <PicsPoints />
            </div>
            <div className="marqueGroupe">
                {showModules ?
                <>
                                <div style={{display:'flex', gap:'7px', flexWrap: 'wrap'}}>
                                            {modules.filter(m => m.isVisible).map((m, i) => (
                                                <span key={i}>
                                                    {React.cloneElement(m.icon, { title: m.statut, className:"smallIconeModule" })}
                                                </span>
                                            ))}
                                        </div>
                </>
                :
                <>
                {groupes?.map((groupe, idx) => {
                    const hasAssistant = groupe.associations_groupe_cours.some(
                        assoc => assoc.assistant_id === assistant.id
                    );
                    if (hasAssistant) {
                        return (
                            <div
                                key={`${assistant.id}-${groupe.id || idx}`}
                                title={groupe.nom}
                                className="iconeGroupe"
                                style={{ backgroundColor: groupe.color, marginRight: "5px", marginBottom: "5px" }}
                            />
                        );
                    }
                    return null;
                })}
                </>
}
            </div>
            <div>
                <div className="assistant-title" style={{ marginTop: '5px' }}>{assistant.sujet}</div>


                <div className="assistant-info">
                    <div className="assistant-date">
                        {new Date(assistant.date).toLocaleDateString("fr-FR", { day: "numeric", month: "long" })}
                    </div>
                    &nbsp;·&nbsp;
                    <div className="assistant-sources">
                        {assistant.files.length} {assistant.files.length < 2 ? "source" : "sources"}
                    </div>
                </div>
            </div>
            <DropdownMenu
                openMenu={openMenuAssistant === assistant.id}
                setOpenMenu={() => setOpenMenuAssistant(null)} // Correct usage
                buttonRef={assistantButtonRefs.current[assistant.id]}
                menuItems={menuItems}
                openDirection="left"
                decalageX={-130}
            />
        </div>
    );
});

export default AssistantCard;
