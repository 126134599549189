// ShareGroupModal.js
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { QRCodeSVG } from 'qrcode.react';
import { serverMain } from '../consts';

import './ShareGroupModal.css';

const ShareGroupModal = ({ onClose, groupe, userInfo }) => {

    const handleClickOutsideModal = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const shareUrl = `${serverMain}/addgroupe/${groupe.code}`;

    const handleCopyEmailTemplate = () => {
        const emailTemplate = `Bonjour,

Vous êtes invité(e) par votre professeur ${userInfo.nom} à rejoindre le groupe ${groupe.nom} sur EduBot.

Pour cela, connectez-vous à votre compte EduBot et entrez le code suivant : ${groupe.code}.

Si vous êtes déjà connecté, il vous suffit d'accéder au lien suivant : ${shareUrl}.

À très bientôt sur EduBot !`;

        navigator.clipboard.writeText(emailTemplate)
            .then(() => {
                alert('Le modèle de mail a été copié dans le presse-papiers.');
            })
            .catch((err) => {
                console.error('Erreur lors de la copie dans le presse-papiers:', err);
            });
    };

    return ReactDOM.createPortal(
        <>
            <div className="modal-overlay-login" onClick={handleClickOutsideModal}>
                <div className="modal-content-access" onClick={(e) => e.stopPropagation()} style={{ width: '600px' }}>
                    <h2 className="titrePartager">Partager le groupe</h2>
                    <div className="share-modal-body">
                        <div className="share-left">
                            <p style={{ marginBottom: '10px' }}>
                                Vos élèves doivent s'abonner à ce groupe pour pouvoir dialoguer avec les chatbots qu'il contient. Pour cela, vous devez leur communiquer le code suivant :
                            </p>
                            <p style={{ textAlign: 'center', marginTop: '0' }}>
                                <strong style={{ fontSize: '24px', userSelect: 'text' }} className="blueColor">{groupe.code}</strong>
                            </p>
                            <p>
                                Vous pouvez également leur diffuser le QR code ci-contre.
                            </p>
                            <p>
                                Cliquez <a href="#" className="blueColor" onClick={(e) => { e.preventDefault(); handleCopyEmailTemplate(); }}>ici</a> pour copier un modèle de mail, à coller dans votre messagerie.
                            </p>
                        </div>
                        <div className="share-right">
                            <div className="qrcode-container">
                                <QRCodeSVG value={shareUrl} size={170} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>,
        document.body
    );
};

export default ShareGroupModal;
