// GroupesMenu.js

import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import './SourcesMenu.css';
import DropdownMenu from '../chatbot/DropDownMenu';
import { serverPHP } from '../consts';
import AddChatbotModal from './AddChatBotModal';
import RemoveChatbotModal from './RemoveChatbotModal';
import { ReactComponent as PicsPlus } from "../pics/plus.svg";
import CreateGroupModal from './CreateGroupModal';
import ShareGroupModal from './ShareGroupModal';
import RenameModal from './RenameModal';

const GroupeItem = React.memo(({ groupe, handleAddChatbot, toggleMenu, openMenu, buttonRef, children }) => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: 'ASSISTANT',
        canDrop: (item) => {
            const isAlreadyAssociated = groupe.associations_groupe_cours.some(
                (assoc) => assoc.assistant_id === item.id
            );
            return !isAlreadyAssociated;
        },
        drop: (item) => handleAddChatbot(item.id, groupe.groupe_id, new Date()),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }), [groupe, handleAddChatbot]);

    const backgroundColor = isOver
        ? canDrop
            ? '#f0f8ff'
            : '#ffe6e6'
        : 'white';

    return (
        <div ref={drop} style={{ backgroundColor }}>
            <div
                className="fileItem itemGroupe"
                onClick={() => toggleMenu(groupe.groupe_id)}
                ref={buttonRef}
            >
                <div className="iconeGroupe" style={{ backgroundColor: groupe.color }} />
                <div>
                    <div className="fileName" style={{ fontWeight: 'bold' }}>{groupe.nom}</div>
                    <div className="fileName">
                        {groupe.associations_groupe_cours.length} {groupe.associations_groupe_cours.length < 2 ? "chatbot" : "chatbots"} ·&nbsp;
                        {groupe.inscriptions_eleves_groupes.length} {groupe.inscriptions_eleves_groupes.length < 2 ? "élève" : "élèves"}
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
});

const GroupesMenu = ({
    setCours,
    groupes,
    setGroupes,
    assistants,
    setRefresh,
    userInfo,
    loaded,
    matieres
}) => {
    const [creatingGroupe, setCreatingGroupe] = useState(false);
    const [showAddChatbotModal, setShowAddChatbotModal] = useState(false);
    const [currentGroupId, setCurrentGroupId] = useState(null);
    const [openMenu, setOpenMenu] = useState(null);
    const buttonRefs = useRef({});
    const [color, setColor] = useState("#B80000");
    const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
    const [groupeName, setGroupeName] = useState('');
    const [groupeNameError, setGroupeNameError] = useState(false);
    const [matiere, setMatiere] = useState(-1);
    const [showShareGroupModal, setShowShareGroupModal] = useState(false);
    const [currentGroupForShare, setCurrentGroupForShare] = useState(null);
    const [showRemoveChatbotModal, setShowRemoveChatbotModal] = useState(false);
    const [currentGroupForRemoval, setCurrentGroupForRemoval] = useState(null);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [renameGroupeName, setRenameGroupeName] = useState('');
    const [currentGroupeIdForRename, setCurrentGroupeIdForRename] = useState(null);

    const handleOpenRenameModal = useCallback((groupe) => {
        setRenameGroupeName(groupe.nom);
        setCurrentGroupeIdForRename(groupe.groupe_id);
        setShowRenameModal(true);
    }, []);

    const handleOpenAddChatbotModal = useCallback((groupId) => {
        setCurrentGroupId(groupId);
        setShowAddChatbotModal(true);
    }, []);

    const handleOpenRemoveChatbotModal = useCallback((groupId) => {
        setCurrentGroupForRemoval(groupId);
        setShowRemoveChatbotModal(true);
    }, []);

    const handleOpenShareGroupModal = useCallback((groupId) => {
        setCurrentGroupForShare(groupId);
        setShowShareGroupModal(true);
    }, []);

    const handleModifyGroupName = useCallback(async (newName) => {
        try {
            const response = await fetch(`${serverPHP}/db/modifyGroupName.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ groupe_id: currentGroupeIdForRename, nom: newName }),
            });
            const data = await response.json();
            if (!data.success) {
                console.error("Erreur lors de la modification du nom du groupe :", data.message || 'Unknown error');
            } else {
                setGroupes((prevGroupes) =>
                    prevGroupes.map((g) =>
                        g.groupe_id === currentGroupeIdForRename ? { ...g, nom: newName } : g
                    )
                );
            }
        } catch (error) {
            console.error("Erreur lors de la modification du nom du groupe :", error);
        }
    }, [currentGroupeIdForRename, setGroupes]);

    const toggleMenu = useCallback((groupeId) => {
        setOpenMenu((prevOpenMenu) => (prevOpenMenu === groupeId ? null : groupeId));
    }, []);

    useEffect(() => {
        groupes.forEach(groupe => {
            if (!buttonRefs.current[groupe.groupe_id]) {
                buttonRefs.current[groupe.groupe_id] = React.createRef();
            }
        });
    }, [groupes]);

    const handleCreationGroupe = useCallback(() => {
        setShowCreateGroupModal(true);
    }, []);

    const handleDeleteGroupe = useCallback(async (groupeId) => {
        try {
            const response = await fetch(`${serverPHP}/db/deleteGroupeInDB.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id: groupeId }),
            });
            const data = await response.json();
            if (data.ok) {
                setGroupes((prevGroupes) => prevGroupes.filter((groupe) => groupe.groupe_id !== groupeId));
                setRefresh(true);
            } else {
                throw new Error("Erreur lors de la suppression du groupe.");
            }
        } catch (error) {
            console.error("Erreur lors de la suppression du groupe :", error);
        }
    }, [setGroupes, setRefresh]);

    const handleSubmitGroupe = useCallback(async () => {
        if (groupeName.trim() === '' || groupeName.length > 40) {
            setGroupeNameError(true);
            return;
        }

        setCreatingGroupe(true);
        const newGroupe = { nom: groupeName.trim(), matiere, auteur: userInfo.id, color };

        try {
            const response = await fetch(`${serverPHP}/db/insertGroupeInDB.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(newGroupe),
            });
            const data = await response.json();
            if (!data.groupe) {
                throw new Error("Erreur lors de la création du groupe.");
            }

            setGroupes((prevGroupes) => [...prevGroupes, data.groupe]);
            setShowCreateGroupModal(false);
            setGroupeName('');
            setGroupeNameError(false);
            setRefresh(true);
        } catch (error) {
            console.error("Erreur lors de la création du groupe :", error);
        } finally {
            setCreatingGroupe(false);
        }
    }, [groupeName, matiere, userInfo.id, color, setGroupes, setRefresh]);

    const groupesParMatiere = useMemo(() => groupes.reduce((acc, groupe) => {
        const matiereId = groupe.matiere;
        if (!acc[matiereId]) acc[matiereId] = [];
        acc[matiereId].push(groupe);
        return acc;
    }, {}), [groupes]);

    const handleRemoveChatbot = useCallback(async (assistantId, groupId) => {
        try {
            const response = await fetch(`${serverPHP}/db/removeChatbotFromGroup.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    assistant_id: assistantId,
                    group_id: groupId,
                }),
            });

            const data = await response.json();
            if (data.success) {
                setGroupes((prevGroupes) =>
                    prevGroupes.map((groupe) => {
                        if (groupe.groupe_id === groupId) {
                            return {
                                ...groupe,
                                associations_groupe_cours: groupe.associations_groupe_cours.filter(
                                    (assoc) => assoc.assistant_id !== assistantId
                                ),
                            };
                        }
                        return groupe;
                    })
                );
            } else {
                throw new Error("Erreur lors de la suppression du chatbot.");
            }
        } catch (error) {
            console.error("Erreur lors de la suppression du chatbot :", error);
        } finally {
            setRefresh(true);
        }
    }, [setGroupes, setRefresh]);

    const handleAddChatbot = useCallback(async (assistantId, groupId, date) => {
        try {
            const response = await fetch(`${serverPHP}/db/addChatbotToGroup.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    assistant_id: assistantId,
                    group_id: groupId,
                    date: date.toISOString().split('T')[0],
                }),
            });

            const data = await response.json();
            if (data.success) {
                setGroupes((prevGroupes) =>
                    prevGroupes.map((groupe) => {
                        if (groupe.groupe_id === groupId) {
                            return {
                                ...groupe,
                                associations_groupe_cours: [
                                    ...groupe.associations_groupe_cours,
                                    { assistant_id: assistantId, date },
                                ],
                            };
                        }
                        return groupe;
                    })
                );
            } else {
                throw new Error("Erreur lors de l'ajout du chatbot.");
            }
        } catch (error) {
            console.error("Erreur lors de l'ajout du chatbot :", error);
        } finally {
            setRefresh(true);
        }
    }, [setGroupes, setRefresh]);

    return (
        <div className="left-column">
            <div style={{ padding: '15px' }}>
                <div className="retourListe retourListeGroupes">
                    <div style={{ fontSize: 'inherit' }}>
                        <span style={{ fontWeight: 'normal', fontSize: '18px' }}>Mes groupes</span>
                    </div>
                    <button onClick={handleCreationGroupe} className="add-file-btn" style={{ display: 'flex' }}>
                        <PicsPlus height={20} width={20} alt="Ajouter" />
                    </button>
                </div>
                {loaded && (
                    <div className="topMenuLeft">
                        {Object.keys(groupesParMatiere).map((matiereId, matiereIndex) => {
                            const matiere = matieres.find(m => m.id === parseInt(matiereId));
                            const groupesPourMatiere = groupesParMatiere[matiereId];
                            return (
                                <div key={`matiere-${matiereIndex}-${matiereId}`} className="groupeMenuGauche">
                                    <h3 className="matiere-title">{matiere?.nom_court || 'Matière inconnue'}</h3>
                                    <ul className="groupes-list">
                                        {groupesPourMatiere.map((groupe, index) => (
                                            <li key={`groupe-${matiereId}-${groupe.groupe_id || index}`}>
                                                <GroupeItem
                                                    groupe={groupe}
                                                    handleAddChatbot={handleAddChatbot}
                                                    toggleMenu={toggleMenu}
                                                    openMenu={openMenu}
                                                    buttonRef={buttonRefs.current[groupe.groupe_id]}
                                                >
                                                    {buttonRefs.current[groupe.groupe_id] && (
                                                        <DropdownMenu
                                                            openMenu={openMenu === groupe.groupe_id}
                                                            setOpenMenu={() => setOpenMenu(null)}
                                                            buttonRef={buttonRefs.current[groupe.groupe_id]}
                                                            menuItems={[
                                                                { label: 'Ajouter des chatbots', onClick: () => handleOpenAddChatbotModal(groupe.groupe_id) },
                                                                ...(groupe.associations_groupe_cours.length > 0 ? [
                                                                    { label: 'Supprimer des chatbots', onClick: () => handleOpenRemoveChatbotModal(groupe.groupe_id) }
                                                                ] : []),
                                                                { label: 'Renommer', onClick: () => handleOpenRenameModal(groupe) },
                                                                { label: 'Supprimer', onClick: () => handleDeleteGroupe(groupe.groupe_id) },
                                                                { label: 'Partager', onClick: () => handleOpenShareGroupModal(groupe.groupe_id) },
                                                            ]}
                                                            openDirection="right"
                                                        />
                                                    )}
                                                </GroupeItem>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                )}

                {/* Modals */}
                {showRemoveChatbotModal && (
                    <RemoveChatbotModal
                        onClose={() => setShowRemoveChatbotModal(false)}
                        groupId={currentGroupForRemoval}
                        handleRemoveChatbot={handleRemoveChatbot}
                        removeChatBotFromGroupe={false}
                        existingTargets={
                            groupes.find(g => g.groupe_id === currentGroupForRemoval)?.associations_groupe_cours.map(assoc => ({
                                id: assoc.assistant_id,
                                sujet: assistants.find(a => a.id === assoc.assistant_id)?.sujet || 'Sujet non défini'
                            })) || []
                        }
                    />
                )}
                {showCreateGroupModal && (
                    <CreateGroupModal
                        onClose={() => {
                            setShowCreateGroupModal(false);
                            setGroupeName('');
                            setGroupeNameError(false);
                        }}
                        handleSubmitGroupe={handleSubmitGroupe}
                        creatingGroupe={creatingGroupe}
                        groupeName={groupeName}
                        setGroupeName={setGroupeName}
                        groupeNameError={groupeNameError}
                        setGroupeNameError={setGroupeNameError}
                        setColor={setColor}
                        matiere={matiere}
                        setMatiere={setMatiere}
                        userInfo={userInfo}
                    />
                )}
                {showAddChatbotModal && (
                    <AddChatbotModal
                        onClose={() => setShowAddChatbotModal(false)}
                        groupId={currentGroupId}
                        handleAddChatbot={handleAddChatbot}
                        addChatBotToGroup={false}
                        availableItems={
                            assistants.filter(assistant => {
                                const group = groupes.find(g => g.groupe_id === currentGroupId);
                                const existingAssistantIds = group?.associations_groupe_cours.map(assoc => assoc.assistant_id) || [];
                                return !existingAssistantIds.includes(assistant.id);
                            })
                        }
                    />
                )}
                {showShareGroupModal && (
                    <ShareGroupModal
                        onClose={() => setShowShareGroupModal(false)}
                        groupe={groupes.find(g => g.groupe_id === currentGroupForShare)}
                        userInfo={userInfo}
                    />
                )}
                {showRenameModal && (
                    <RenameModal
                        message="Nouveau nom de groupe :"
                        var={renameGroupeName}
                        setVar={(newName) => {
                            setRenameGroupeName(newName);
                            setGroupes((prevGroupes) =>
                                prevGroupes.map((g) =>
                                    g.groupe_id === currentGroupeIdForRename ? { ...g, nom: newName } : g
                                )
                            );
                        }}
                        onClose={() => setShowRenameModal(false)}
                        onModify={handleModifyGroupName}
                    />
                )}
            </div>
        </div>
    );
};

export default GroupesMenu;
