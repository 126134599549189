// AccountForm.jsx

import React, { useState, useEffect, useRef } from 'react';
import './AccountForm.css';
import { serverPHP } from '../consts';
import { useNavigate } from 'react-router';
import { ReactComponent as PicsFleche_Gauche } from "../pics/fleche_gauche.svg";
import LoadingCircle from './LoadingCircle';

const AccountForm = ({ goBack, isTeacher, matieres, setInfo }) => {
    const [error, setError] = useState(null);
    const [showAccessModal, setShowAccessModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [shake, setShake] = useState(false);

    const [usernameSignIn, setUsernameSignIn] = useState('');
    const [emailSignIn, setEmailSignIn] = useState('');
    const [passwordSignIn, setPasswordSignIn] = useState('');
    const [confirmPasswordSignIn, setConfirmPasswordSignIn] = useState('');

    const [usernameError, setUsernameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [waiting, setWaiting] = useState(false);

    // State variables for matieres
    const [selectedMatieres, setSelectedMatieres] = useState([]);
    const [matiereInput, setMatiereInput] = useState('');
    const [showMatiereSuggestions, setShowMatiereSuggestions] = useState(false);

    const [isSelectingSuggestion, setIsSelectingSuggestion] = useState(false);
    const [isMouseDownInsideModal, setIsMouseDownInsideModal] = useState(false);

    const matiereInputRef = useRef(null);

    const navigate = useNavigate();



    const teacherDomains = [
        "@ac-aix-marseille.fr", "@ac-amiens.fr", "@ac-besancon.fr", "@ac-bordeaux.fr",
        "@ac-caen.fr", "@ac-clermont.fr", "@ac-creteil.fr", "@ac-corse.fr",
        "@ac-dijon.fr", "@ac-grenoble.fr", "@ac-guadeloupe.fr", "@ac-guyane.fr",
        "@ac-lille.fr", "@ac-limoges.fr", "@ac-lyon.fr", "@ac-martinique.fr",
        "@ac-mayotte.fr", "@ac-montpellier.fr", "@ac-nancy-metz.fr", "@ac-nantes.fr",
        "@ac-nice.fr", "@ac-orleans-tours.fr", "@ac-paris.fr", "@ac-poitiers.fr",
        "@ac-reims.fr", "@ac-rennes.fr", "@ac-reunion.fr", "@ac-rouen.fr",
        "@ac-strasbourg.fr", "@ac-toulouse.fr", "@ac-versailles.fr"
    ];

    const checkTeacherEmail = (email) => {
        return teacherDomains.some(domain => email.endsWith(domain));
    };

    useEffect(() => {
        if (error !== null) {
            triggerShake();
            setShowModal(true);
        }
    }, [error]);

    const triggerShake = () => {
        setShake(true);
        setTimeout(() => setShake(false), 500);
    };

    // On mount, reset inputs
    useEffect(() => {
        setUsernameSignIn('');
        setEmailSignIn('');
        setPasswordSignIn('');
        setConfirmPasswordSignIn('');
        setSelectedMatieres([]);
        setMatiereInput('');
        setShowMatiereSuggestions(false);
    }, []);

    // Validation functions
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isAcademicEmail = checkTeacherEmail(emailSignIn);
        const academicOK = ((isTeacher && isAcademicEmail) || (!isTeacher && !isAcademicEmail));

        return re.test(email) && academicOK;
    };

    const handleUsernameChange = (e) => {
        const value = e.target.value;
        setUsernameSignIn(value);
        if (value.length > 40 || !value) {
            setUsernameError(true);
        } else {
            setUsernameError(false);
        }
    };

    const handleEmailChange = () => {
        if (!validateEmail(emailSignIn) && emailSignIn !== "") {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPasswordSignIn(value);
        if (value.length > 40 || !value) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPasswordSignIn(value);
        setConfirmPasswordError(false);
    };

    // Functions for matieres selection
    const addMatiere = (matiere) => {
        if (!selectedMatieres.some(m => m.id === matiere.id)) {
            setSelectedMatieres([...selectedMatieres, matiere]);
        }
        setMatiereInput('');
        setShowMatiereSuggestions(false);
        if (matiereInputRef.current) {
            matiereInputRef.current.focus();
        }
    };

    const removeMatiere = (id) => {
        setSelectedMatieres(selectedMatieres.filter(m => m.id !== id));
    };

    const handleMatiereInputChange = (e) => {
        setMatiereInput(e.target.value);
        setShowMatiereSuggestions(true);
    };

    const filteredMatieres = matieres
        .filter(matiere => {
            const searchText = matiereInput.toLowerCase();
            return (
                (matiere.nom_long.toLowerCase().includes(searchText) || matiere.nom_court.toLowerCase().includes(searchText)) &&
                !selectedMatieres.some(selected => selected.id === matiere.id)
            );
        })
        .sort((a, b) => {
            const searchText = matiereInput.toLowerCase();
            const aStartsWith = a.nom_long.toLowerCase().startsWith(searchText) || a.nom_court.toLowerCase().startsWith(searchText);
            const bStartsWith = b.nom_long.toLowerCase().startsWith(searchText) || b.nom_court.toLowerCase().startsWith(searchText);

            if (aStartsWith && !bStartsWith) return -1;
            if (!aStartsWith && bStartsWith) return 1;

            // Prioritize exact matches
            const aExactMatch = a.nom_long.toLowerCase() === searchText || a.nom_court.toLowerCase() === searchText;
            const bExactMatch = b.nom_long.toLowerCase() === searchText || b.nom_court.toLowerCase() === searchText;

            if (aExactMatch && !bExactMatch) return -1;
            if (!aExactMatch && bExactMatch) return 1;

            return 0;
        });

    const handleMatiereInputBlur = () => {
        setTimeout(() => {
            if (isSelectingSuggestion) {
                // Do nothing if a suggestion is being selected
                setIsSelectingSuggestion(false);
                return;
            }
            if (matiereInput !== "") {
                setInfo(<><p>Vous devez valider chaque matière enseignée <strong>dans la liste déroulante</strong>.</p>
                    <p>Si vous ne trouvez pas votre matière, veuillez choisir <strong>"Autre"</strong> ; contactez l'assistance de l'application pour suggérer un ajout.</p></>);
                setMatiereInput("");
                setShowMatiereSuggestions(false);
            } else {
                setShowMatiereSuggestions(false);
            }
        }, 200);
    };

    const handleSignUpKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        // Validation checks

        if (!usernameSignIn || !emailSignIn || !passwordSignIn || !confirmPasswordSignIn) {
            setError('Tous les champs indiqués doivent être remplis !');
            return;
        }

        if (!validateEmail(emailSignIn)) {
            setError('L\'adresse e-mail n\'est pas valide.');
            return;
        }

        if (usernameSignIn.length > 40 || emailSignIn.length > 40 || passwordSignIn.length > 40) {
            setError('Le nom, l\'adresse e-mail et le mot de passe ne doivent pas dépasser 40 caractères.');
            return;
        }

        if (passwordSignIn !== confirmPasswordSignIn) {
            setError('Les mots de passe renseignés ne correspondent pas.');
            return;
        }

        // Vérification du type d'utilisateur et du domaine de l'e-mail
        const isAcademicEmail = checkTeacherEmail(emailSignIn);

        if (isTeacher && !isAcademicEmail) {
            setError('Il est nécessaire d\'entrer une adresse mail académique pour créer un compte enseignant.');
            return;
        }

        if (!isTeacher && isAcademicEmail) {
            setError('Veuillez ne pas utiliser d\'adresse mail académique pour créer un compte élève.');
            return;
        }

        if (isTeacher && selectedMatieres.length === 0) {
            setError('Veuillez sélectionner au moins une matière.');
            return;
        }

        // Prepare data for submission
        const submitData = {
            nom: usernameSignIn,
            mail: emailSignIn,
            password: passwordSignIn,
            matieres: isTeacher ? selectedMatieres.map(m => m.id) : [],
        };

        try {
            setWaiting(true);

            let url = serverPHP + '/db/signUpUser.php';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(submitData),
            });

            const data = await response.json();
            setWaiting(false);

            if (data.status === 'error') {
                setError(data.message || 'Erreur lors de l\'opération.');
            } else if (data.status === 'success') {
                setInfo(<>
                    <p>Un mail vient d'être envoyé à l'adresse indiquée. Veuillez cliquer sur le lien qu'il contient pour <strong>activer votre compte.</strong></p>
                </>);
                // Reset inputs
                setUsernameSignIn('');
                setEmailSignIn('');
                setPasswordSignIn('');
                setConfirmPasswordSignIn('');
                setSelectedMatieres([]);
                setMatiereInput('');
                goBack();
            } else {
                setError('Réponse inconnue du serveur.');
            }
        } catch (err) {
            setError('Erreur lors de l\'opération. Veuillez réessayer.');
        }
    };

    const handleClickOutsideModal = (e) => {
        if (!isMouseDownInsideModal && e.target === e.currentTarget) {
            setShowModal(false);
            setShowAccessModal(false);
            setError(null);
        }
    };

    return (
        <>
            {
                waiting ?
                    <>
                        <LoadingCircle taille={150} />
                        <div className="inscriptionEnCours">
                            Inscription en cours...
                        </div>
                    </>
                    :
                    <>
                        <h1 className="login-title" style={{ fontSize: '26px' }}>
                            {isTeacher ? 'Inscription enseignant·e' : 'Inscription élève'}
                        </h1>
                        <div className="login-form small" style={{ marginLeft: "20px" }}>
                            <div className="input-group">
                                <label htmlFor="username" className="login-label">Nom affiché :</label>
                                <input
                                    type="text"
                                    id="username"
                                    className={`login-input ${usernameError ? 'input-error' : ''}`}
                                    value={usernameSignIn}
                                    onChange={handleUsernameChange}
                                    onKeyDown={handleSignUpKeyPress}
                                />
                        </div>
                        <div className="input-group">
                                <label htmlFor="email" className="login-label">Adresse e-mail :</label>
                                <input
                                    type="text"
                                    id="email_eleve"
                                    className={`login-input ${emailError ? 'input-error' : ''}`}
                                    value={emailSignIn}
                                    onChange={(e) => {
                                        setEmailSignIn(e.target.value);
                                        setEmailError(false);
                                    }}
                                    onBlur={handleEmailChange}
                                    onKeyDown={handleSignUpKeyPress}
                                />
                            </div>

                            {isTeacher ? 
                                <div className="input-group">
                                    <label htmlFor="matiere" className="login-label">Matière(s) :</label>
                                    <div className="matiere-input-container">
                                        {selectedMatieres.map(matiere => (
                                            <div key={matiere.id} className="selected-matiere">
                                                {matiere.nom_court}
                                                <span className="remove-matiere" onClick={() => removeMatiere(matiere.id)}>×</span>
                                            </div>
                                        ))}
                                        <input
                                            type="text"
                                            id="matiere"
                                            className="matiere-input"
                                            value={matiereInput}
                                            onChange={handleMatiereInputChange}
                                            onFocus={() => setShowMatiereSuggestions(true)}
                                            onBlur={handleMatiereInputBlur}
                                            ref={matiereInputRef}
                                            autoComplete="off"
                                        />
                                        {showMatiereSuggestions && (
                                            <ul className="matiere-suggestions">
                                                {filteredMatieres.map((matiere) => (
                                                    <li key={matiere.id}
                                                        onMouseDown={() => setIsSelectingSuggestion(true)}
                                                        onClick={() => {
                                                            addMatiere(matiere);
                                                            setIsSelectingSuggestion(false);
                                                        }}>
                                                        {matiere.nom_long}
                                                    </li>
                                                ))}
                                                <li
                                                    style={{ fontWeight: 'bold' }}
                                                    onMouseDown={() => setIsSelectingSuggestion(true)}
                                                    onClick={() => {
                                                        addMatiere({ id: -1, nom_long: 'Autre', nom_court: 'Autre' });
                                                        setIsSelectingSuggestion(false);
                                                    }}
                                                >
                                                    Autre
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            : ""}

                            <div className="input-group">
                                <label htmlFor="password" className="login-label">Mot de passe :</label>
                                <input
                                    type="password"
                                    id="password"
                                    className={`login-input ${passwordError ? 'input-error' : ''}`}
                                    value={passwordSignIn}
                                    onChange={handlePasswordChange}
                                    onKeyDown={handleSignUpKeyPress}
                                />
                            </div>

                            <div className="input-group">
                                <label htmlFor="confirm-password" className="login-label">Confirmer :</label>
                                <input
                                    type="password"
                                    id="confirm-password"
                                    className={`login-input ${confirmPasswordError ? 'input-error' : ''}`}
                                    value={confirmPasswordSignIn}
                                    onChange={handleConfirmPasswordChange}
                                    onKeyDown={handleSignUpKeyPress}
                                    onBlur = {() => {
                                        if (confirmPasswordSignIn !== passwordSignIn) {
                                            setConfirmPasswordError(true);
                                        } else {
                                            setConfirmPasswordError(false);
                                        }
                                    }
                                    }
                                />
                            </div>
                        </div>
                        <div className="button-container">
                            <button className="login-button" onClick={handleSubmit}>Valider</button>
                            <button className="signup-button" onClick={() => navigate("/")}>
                                <PicsFleche_Gauche width={20} height={20} className="flecheGauche" alt="Retour" />Retour
                            </button>
                        </div>
                    </>
            }

            {showModal && (
                <div className="modal-overlay-login" onClick={handleClickOutsideModal}>
                    <div className="modal-content-login">
                        {error && <p>{error}</p>}
                    </div>
                </div>
            )}
        </>
    );
};

export default AccountForm;
