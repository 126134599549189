// ModuleMindmap_parametres.js

import React, { useState, useEffect } from 'react';
import './ModuleMindmap.css';
import { serverPHP } from '../../consts';
import LoadingCircle from '../LoadingCircle';
import Modal from './Modal'; // Import the separate Modal component

const ModuleMindmap_parametres = ({
  contenuFichier,
  setContenuFichier,
  setTitreMenu,
  cours,
  setCours,
  setCurrentMindmap,
  setFichierFromSources
}) => {

  const MindMapPrompts = [
    {
      label: "Plan",
      prompt: "Schématise le plan du document fourni, en identifiant les parties et sous-parties. Tu ne dois donner QUE des éléments de type \"I. Intitulé de la première partie\", \"1. Intitulé de la première sous-partie\", etc., et rien d'autre. Prends le temps de développer les intitulés, qui doivent être suffisamment précis, et articule les éléments entre eux."
    },
    {
      label: "Idées",
      prompt: "Schématise les principales idées présentées par le document fourni, et les relations qu'elles entretiennent entre elles. Il ne s'agit pas d'identifier le plan du texte, mais plutôt les idées générales qui en sous-tendent les articulations."
    },
    {
      label: "Problématique",
      prompt: "Dans le document fourni, identifie une question centrale, et développe autour d'elle des arguments, des contre-arguments, ainsi que les éléments qui permettent d’y répondre."
    },
    {
      label: "Questions",
      prompt: "Identifie l'ensemble des questions qui sont soulevées par ce document, et organise-les selon leurs rapports."
    },
    {
      label: "Personnaliser",
      prompt: ""
    }
  ];
  
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState(MindMapPrompts[0].prompt);
  const [nomFichier, setNomFichier] = useState("Carte mentale 1");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setTitreMenu("2. Paramètres");
  }, [setTitreMenu]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = (updatedContent) => {
    setContenuFichier(updatedContent);  // Update content only on modal close
    setIsModalOpen(false);
  };

  const handleGenerateMindmap = () => {
    if (!nomFichier.trim()) {
      setNomFichier("Carte mentale 1");
    }

    setLoading(true);
    fetch(`${serverPHP}/moduleMindmap_handleCreation.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prompt: prompt,
        contenuFichier: contenuFichier,
        nomFichier: nomFichier,
        assistant_id: cours.id
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Response from server:', data);
        if (data) {
          setCours(prevCours => ({
            ...prevCours,
            modules: {
              ...prevCours.modules,
              cartementale: [...(prevCours.modules.cartementale || []), data]
            }
          }));
          setLoading(false);
          setFichierFromSources(null);
          setContenuFichier(null);
          setCurrentMindmap(data);
        } else {
          console.error('Invalid data format received:', data);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  };

  const handleNomFichierBlur = () => {
    if (!nomFichier.trim()) {
      setNomFichier("Carte mentale 1");
    }
  };

  const handlePromptSelection = (event) => {
    const selectedPrompt = event.target.value;
    setPrompt(selectedPrompt);
  };

  return (
    <>
      <div className="moduleContainer" style={{ position: 'relative', height: '450px' }}>
        {loading ? (
          <>
            <LoadingCircle taille={150} />
            <div className="generationQCMEnCours">
              Génération de la carte mentale en cours...<br /><span style={{ fontSize: '14px' }}>Cette opération peut prendre une vingtaine de secondes</span>
            </div>
          </>
        ) : (
          <>
            <div className="moduleTitre">Choisissez les <strong>paramètres</strong></div>
            <div className="login-form small" style={{ marginLeft: 0 }}>
              <div className="input-group">
                <label className="login-label parametresQCM">Nom de la carte :</label>
                <input
                  type="text"
                  value={nomFichier}
                  onChange={(e) => setNomFichier(e.target.value)}
                  onBlur={handleNomFichierBlur}
                  className="login-input"
                  style={{ width: '150px' }}
                />
              </div>
              <div className="input-group">
                <label className="login-label parametresQCM">Texte chargé :</label>
                <button onClick={openModal} className="login-button boutonQCM" style={{ margin: 0, maxWidth: '150px' }}>Modifier</button>
              </div>
              <div className="input-group">
                <label className="login-label parametresQCM">Instructions :</label>
                <select
                  onChange={handlePromptSelection}
                  style={{ width: '100%', maxWidth: '150px' }}
                >
                  {MindMapPrompts.map((item, index) => (
                    <option key={index} value={item.prompt}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                className="modal-textarea textarea"
              />
            </div>
            <button
              className="login-button"
              style={{ width: '250px', margin: 'auto' }}
              onClick={handleGenerateMindmap}
            >
              Générer la carte mentale
            </button>
          </>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        initialContent={contenuFichier}
      />
    </>
  );
};

export default ModuleMindmap_parametres;
