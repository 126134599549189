import { useEffect, useState, useMemo, useCallback } from "react";
import { serverPHP } from "../consts";
import SetAssistant from "./SetAssistant";
import ListeMenuProfs from "./ListeMenuProf";
import AddModule from "./AddModule";
import { useNavigate } from 'react-router';

const MainMenuProf = ({ userInfo, setUserInfo, matieres, chatbot_id }) => {
    const [groupes, setGroupes] = useState([]);
    const [assistants, setAssistants] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();
    const [showModules, setShowModules] = useState(false);


    const cours = useMemo(() => assistants.find(a => a.id === chatbot_id), [assistants, chatbot_id]);

    const formatDateForDB = useCallback((dateInput) => {
        const date = dateInput instanceof Date ? dateInput : new Date(dateInput);
        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateInput);
            return null;
        }
        const offsetDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        return offsetDate.toISOString().split("T")[0];
    }, []);

    const updateAssistant = useCallback((newCours) => {
        console.log("On passe par updateAssistant");
        console.log(newCours);

        const updatedCours = typeof newCours === 'function' ? newCours(cours) : newCours;
        setCours(updatedCours);

        const dataToSend = {
            ...updatedCours,
            date: formatDateForDB(updatedCours.date),
        };

        fetch(serverPHP + "/db/updateAssistant.php", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataToSend),
        })
        .then(response => {
            if (!response.ok) {
                return response.json().then(data => {
                    console.error("Erreur lors de la mise à jour :", data.error || "Erreur inconnue");
                });
            }
        })
        .catch(error => {
            console.error("Erreur lors de la mise à jour de l'assistant :", error);
        });
    }, [cours, formatDateForDB]);

    const fetchData = useCallback(async () => {
        try {
            const response = await fetch(serverPHP + "/db/getDataProf.php", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ user_id: userInfo.id }),
            });
            const data = await response.json();
            if (data.error) throw new Error(data.error);

            const formattedAssistants = data.assistants.map(assistant => ({
                ...assistant,
                date: new Date(assistant.date),
            }));
            setAssistants(formattedAssistants);

            const formattedGroupes = data.groupes.map(groupe => {
                const dates = groupe.associations_groupe_cours
                    .map(assoc => new Date(assoc.date))
                    .filter(date => !isNaN(date.getTime()));
                const latestDate = dates.length > 0 ? new Date(Math.max(...dates.map(date => date.getTime()))) : null;

                return {
                    ...groupe,
                    latestAssociationDate: latestDate,
                    nombreChatbots: groupe.associations_groupe_cours.length,
                    nombreEleves: groupe.inscriptions_eleves_groupes.length,
                };
            });

            setGroupes(formattedGroupes);
            setLoaded(true);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    }, [userInfo.id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (refresh) {
            fetchData();
            setRefresh(false);
        }
    }, [refresh, fetchData]);

    const setCours = useCallback((update) => {
        setAssistants(prevAssistants =>
            prevAssistants.map(assistant =>
                assistant.id === chatbot_id ? (typeof update === 'function' ? update(assistant) : { ...assistant, ...update }) : assistant
            )
        );
    }, [chatbot_id]);

    return (
        <div className="container">
            {(cours == null || cours?.id == null) ? (
                <ListeMenuProfs
                    userInfo={userInfo}
                    setCours={setCours}
                    setUserInfo={setUserInfo}
                    groupes={groupes}
                    setGroupes={setGroupes}
                    assistants={assistants}
                    setRefresh={setRefresh}
                    loaded={loaded}
                    setAssistants={setAssistants}
                    matieres={matieres}
                    showModules={showModules}
                    setShowModules={setShowModules}
                />
            ) : (
                <SetAssistant
                    cours={cours}
                    setCours={setCours}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    updateAssistant={updateAssistant}
                    suggestions={cours.suggestions}
                />
            )}
        </div>
    );
};

export default MainMenuProf;
