import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './Modules.css';
import Reveal from 'reveal.js';
import { marked } from 'marked';

import 'reveal.js/dist/reveal.css';
import 'reveal.js/dist/theme/white.css'; // Choose any theme you like

const ModuleDiapo_ShowDiapo = ({ currentDiapo, setCurrentDiapo, canModify = true }) => {
  const [diapoCode, setDiapoCode] = useState(currentDiapo.content);
  const [error, setError] = useState(null);
  const [showSourceEditor, setShowSourceEditor] = useState(false);
  const [loading, setLoading] = useState(false);
  const presentationRef = useRef(null);
  const sourceEditorRef = useRef(null);
  const deckRef = useRef(null);

  // Update diapoCode when currentDiapo changes
  useEffect(() => {
    setDiapoCode(currentDiapo.content);
  }, [currentDiapo]);

  // Initialize Reveal.js when diapoCode changes
  useEffect(() => {
    initializeReveal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diapoCode]);

  const initializeReveal = () => {
    setLoading(true);
    setError(null);

    // Clean up existing presentation
    if (deckRef.current) {
      deckRef.current.destroy();
      deckRef.current = null;
    }

    // Initialize new presentation
    if (presentationRef.current) {
      const deck = new Reveal(presentationRef.current, {
        controls: true,
        progress: true,
        history: false,
        center: true,
        slideNumber: true,
        // Other options as needed
      });

      deck
        .initialize()
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.error('Reveal.js initialization error:', err);
          setError('Erreur lors du rendu de la présentation.');
          setLoading(false);
        });

      deckRef.current = deck;
    }
  };

  // Close modal
  const closeModal = () => {
    setShowSourceEditor(false);
    setCurrentDiapo(null);
  };

  // Prevent body scrolling when modal is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  // Handle clicks outside the source editor to close it
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        showSourceEditor &&
        sourceEditorRef.current &&
        !sourceEditorRef.current.contains(e.target) &&
        !e.target.classList.contains('login-button') // Prevent toggle on the "Modifier source" button
      ) {
        setShowSourceEditor(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSourceEditor]);

  const toggleSourceEditor = (e) => {
    e.stopPropagation();
    setShowSourceEditor(!showSourceEditor);
  };

  // Download the Markdown code as a .md file
  const handleDownload = () => {
    const blob = new Blob([diapoCode], { type: 'text/markdown' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'presentation.md';
    link.href = url;
    link.click();
  };

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (deckRef.current) {
        deckRef.current.destroy();
        deckRef.current = null;
      }
    };
  }, []);

  // Split the diapoCode into slides
  const slidesContent = diapoCode.split(/^\s*---\s*$/m);

  return (
    <>
      {ReactDOM.createPortal(
        <div
          className="modal-overlay"
          onClick={closeModal}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{
              backgroundColor: 'white',
              padding: '10px',
              width: '90%',
              height: '90%',
              maxWidth: '90vw',
              maxHeight: '90vh',
              overflow: 'hidden',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* Source editor overlay */}
            {showSourceEditor && (
              <div
                onClick={(e) => e.stopPropagation()}
                className="sourceEditor"
                ref={sourceEditorRef}
              >
                <textarea
                  className="textareaSVG"
                  value={diapoCode}
                  onChange={(e) => setDiapoCode(e.target.value)}
                />
              </div>
            )}

            {/* Presentation display */}
            {(loading && false) && <div>Chargement de la présentation...</div>}
            {!loading && error && (
              <div className="erreurSVG">
                {error}
                <br />
                Veuillez vérifier la conformité du code.
              </div>
            )}
            <div
              className="reveal"
              ref={presentationRef}
              style={{ height: '100%', width: '100%', cursor:'default!important' }}
            >
              <div className="slides">
                {slidesContent.map((slideContent, index) => (
                  <section
                    key={index}
                    dangerouslySetInnerHTML={{ __html: marked(slideContent) }}
                  />
                ))}
              </div>
            </div>

            {/* Buttons at the bottom */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              {canModify && (
                <button
                  onClick={toggleSourceEditor}
                  style={{ marginRight: '10px' }}
                  className="login-button"
                >
                  Modifier source
                </button>
              )}
              <button onClick={handleDownload} className="login-button">
                Télécharger
              </button>
            </div>
          </div>
        </div>,
        document.body
      )}
    </>
  );
};

export default ModuleDiapo_ShowDiapo;