import React, { useState, useEffect, useRef } from 'react';
import './RenameModal.css';

const RenameModal = ({ message, var: currentVar, setVar, onClose, onModify }) => {
    const [localVar, setLocalVar] = useState(currentVar);
    const modalRef = useRef();

    useEffect(() => {
        // Close modal on outside click
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleModify = () => {
        setVar(localVar);
        onModify(localVar); // Passer la nouvelle valeur à onModify
        onClose();
    };

    const handleCancel = () => {
        setLocalVar(currentVar); // Reset to original value on cancel
        onClose();
    };

    return (
        <div className="rename-modal-overlay">
            <div className="rename-modal-content" ref={modalRef}>
                <p>{message}</p>
                <input
                    type="text"
                    value={localVar}
                    onChange={(e) => setLocalVar(e.target.value)}
                    className="rename-input"
                />
                <div className="rename-modal-buttons">
                    <button onClick={handleModify} className="login-button">Modifier</button>
                    <button onClick={handleCancel} className="signup-button">Annuler</button>
                </div>
            </div>
        </div>
    );
};

export default RenameModal;
