import React from "react";
import "./LoadingDots.css";

const LoadingDots = () => {
  return (
<div className="loader">
  <span className="loader__element"></span>
  <span className="loader__element"></span>
  <span className="loader__element"></span>
</div>
  );
};

export default LoadingDots;
