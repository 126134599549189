import { useState } from 'react';
import { formatFileSize } from '../../consts';

const FileList = ({ listeFichiers, checkIfFileIsAllowed = true, isSource, selectFile, isFile = true, onCheckboxChange, onClickButton, image, opacity = 0.5, hoverAll = false, filterTxt = false,
  noFileMsg = <>
  Ajoutez d'abord une source à votre chatbot en utilisant le menu
  situé sur la gauche.
  <br />
  Il faudra ensuite autoriser le partage en cliquant sur la case
  cliquable qui s'affichera ici.
</>
 }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const filterExtensions = [
    'odt', 'pdf', 'docx', 'html', 'htm', 'md', 'txt', 'rtf', 'epub', 'xml'
  ];

  const handleClickAll = (file, index) => {
    if (isSource) {
      onCheckboxChange(index);
    } else if (hoverAll) {
      onClickButton(file);
    }
  };

  const isFileAllowed = (filename) => {
    if (checkIfFileIsAllowed) {
    const fileExtension = filename.split('.').pop().toLowerCase();
    return filterExtensions.includes(fileExtension);
  } else return true;
   
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="containerFichiersPartage">
        {listeFichiers.map((file, index) => {
          const allowed = isFileAllowed(file.filename);
          const isFiltered = filterTxt && !allowed;

          return (
            <div
              key={index}
              className="partageItem"
              onClick={() => {
                if (!selectFile) {
                  if (!isFiltered) handleClickAll(file, index);
                } else {
                  //console.log("hello");
                  onClickButton(file);
                }
              }
              }
              onMouseEnter={() => {
                //if (hoverAll && !isFiltered) 
                  setHoveredIndex(index);
              }}
              onMouseLeave={() => {
                if (hoverAll && !isFiltered) setHoveredIndex(null);
              }}
              style={{
                cursor: (isFiltered && isFile) ? 'not-allowed' : (hoverAll ? 'pointer' : 'default'),
                backgroundColor:
                  (isFiltered && isFile) ? '#ffcaca' :
                    hoveredIndex === index && hoverAll && !isFiltered
                      ? '#e3effb'
                      : 'var(--menu-bg-color)',
              }}
            >

              <div>
                {isFile &&
                <>
                {isSource && !selectFile ? (
                  <input
                    type="checkbox"
                    checked={file.is_shared ?? false}
                    onChange={() => onCheckboxChange(index)}
                    onClick={(e) => e.stopPropagation()}
                    disabled={isFiltered}
                  />
                ) : (
                  <div
                    className="actionButton"
                    onClick={(e) => {
                      if (!isFiltered) {
                        e.stopPropagation();
                        onClickButton(file); // Pass the entire file object
                      }
                    }}
                    onMouseEnter={() => {
                      if (!hoverAll && !isFiltered) setHoveredIndex(index);
                    }}
                    onMouseLeave={() => {
                      if (!hoverAll && !isFiltered) setHoveredIndex(null);
                    }}
                    style={{
                      filter: `opacity(${hoveredIndex === index && !isFiltered ? 1 : opacity}) grayscale(${hoveredIndex === index && !isFiltered ? 0 : 1})`,
                      transition: 'filter 0.2s ease',
                      cursor: isFiltered ? 'not-allowed' : 'pointer',
                    }}
                  >
                    {image}
                  </div>
                )}
                </>
        }
                <span className={isFile ? 'textEllipsis' : ''}>{file.filename}{(isFiltered && isFile) && <span style={{ fontStyle: 'italic', marginLeft: '5px' }}>(Extension non-autorisée)</span>}</span>
              </div>
              {isFile &&
              <span className="partageTaille">
                ({`${formatFileSize(file.filesize).size} ${formatFileSize(file.filesize).unit}`.trim()})
              </span>
        }
            </div>
          );
        })}
      </div>
      {listeFichiers.length === 0 ? (
        <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
          {isSource ? (
            noFileMsg
          ) : (
            "Pour partager un fichier sans qu'il soit inclus dans les sources, cliquez sur l'icône '+' ci-dessus."
          )}
        </p>
      ) : (
        (isSource && !selectFile) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '30px',
              gap: '20px',
              marginBottom: '-10px',
            }}
          >
            <button
              onClick={() => onCheckboxChange('all', true)}
              className="login-button"
            >
              Tout sélectionner
            </button>
            <button
              onClick={() => onCheckboxChange('all', false)}
              className="signup-button"
              style={{ marginLeft: '10px' }}
            >
              Tout désélectionner
            </button>
          </div>
        )
      )}
    </div>
  );
};

export default FileList;
