// SettingsModal.jsx
import React, { useEffect, useRef, useState, useCallback } from 'react';
import './SettingsModal.css';
import { ReactComponent as InstructionsIcon } from "../pics/instructionsIcon.svg";
import { ReactComponent as AccueilIcon } from "../pics/accueilIcon.svg";
import { ReactComponent as SuggestionsIcon } from "../pics/suggestionsIcon.svg";
import { ReactComponent as PicsDelete } from "../pics/delete.svg";


import { serverPHP } from '../consts';

const SettingsModal = ({ isOpen, onClose, cours, setCours, updateAssistant }) => {
  const [selectedTab, setSelectedTab] = useState('instructions');
  const [localInstructions, setLocalInstructions] = useState("");
  const [localAccroche, setLocalAccroche] = useState("");
  const [newSuggestion, setNewSuggestion] = useState("");
  const suggestionsListRef = useRef(null);

  const modalRef = useRef(null);

  useEffect(() => {
    if (cours && cours.instructions) setLocalInstructions(cours.instructions);
  }, [cours]);

  useEffect(() => {
    if (cours && cours.accroche) setLocalAccroche(cours.accroche);
  }, [cours]);

  const updateInstructions = () => {
    if (!cours.assistant_id || !localInstructions) {
      console.error("assistant_id ou instructions manquant.");
      return;
    }
  
  
    // Disable the chat by temporarily removing the assistant_id
    updateAssistant(prevCours => ({ ...prevCours, assistant_id: null }));
  
    // Send the new instructions via a POST request to modifyassistant.php
    fetch(serverPHP + "/modifyassistant.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        assistant_id: cours.id,
        instructions: localInstructions
      })
    })
    .then((response) => response.json())
    .then((assistantData) => {
      if (assistantData.id) {
        console.log("Assistant updated successfully:", assistantData);
        // Update cours with the new assistant_id and the latest instructions
        updateAssistant(prevCours => ({
          ...prevCours,
          assistant_id: assistantData.id,
          instructions: localInstructions // Ensure the updated instructions are included
        }));
      } else {
        console.error("Error updating assistant: no ID found in the response.");
      }
    })
    .catch((error) => {
      console.error("Error updating assistant:", error);
    });
  };

  // Memoize closeAndSave to ensure it has the latest state
  const closeAndSave = useCallback(() => {
    //console.log("on passe ici", cours);
    if (cours.instructions !== localInstructions) 
        {
          //console.log("par là")
            updateInstructions(cours);//update instructions
        }
    // Les messages se réinitialisent automatiquement en cas de changement de l'accroche

    updateAssistant({
      ...cours,
      instructions: localInstructions,
      accroche: localAccroche
    });

    onClose();
  }, [localInstructions, localAccroche, updateAssistant, onClose, cours]);

  // Define handleClickOutside outside of useEffect
  const handleClickOutside = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeAndSave();
      }
    },
    [closeAndSave]
  );

  // Close modal on outside click
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  if (!isOpen || !cours) return null;

  const reset = () => {
    if (selectedTab === 'instructions') {
      setLocalInstructions(`Tu es un assistant serviable. C'est le professeur qui t'a fourni les documents : appuie-toi sur eux pour répondre aux questions de l'élève. Utilise une mise en forme MarkDown (spécifiquement le gras) pour mettre en avant les informations les plus importantes. Si on t'interroge sur un autre sujet que le cours, refuse poliment et recadre la discussion.`);
    } else if (selectedTab === 'accueil') {
      setLocalAccroche(`Bonjour, je suis là pour répondre à toutes tes questions sur le cours.`);
    } else if (selectedTab === 'suggestions') {
      setNewSuggestion("");
    }
  };

  const handleAddSuggestion = () => {
    if (!cours.assistant_id || !newSuggestion.trim()) {
      console.error("assistant_id ou suggestion manquant.");
      return;
    }
  
    const suggestionToAdd = newSuggestion.trim();
  
    fetch(serverPHP + "/db/addSuggestion.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        assistant_id: cours.id,
        suggestion: suggestionToAdd
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.id && data.suggestion) {
        // Add the new suggestion to the state
        setCours(prevCours => ({
          ...prevCours,
          suggestions: [...(prevCours.suggestions || []), { id: data.id, suggestion: data.suggestion }]
        }));
        setNewSuggestion("");
  
        // Scroll to the top of the suggestions list
        if (suggestionsListRef.current) {
          suggestionsListRef.current.scrollTop = 0;        }
      } else {
        console.error("Erreur lors de l'ajout de la suggestion:", data.message);
      }
    })
    .catch(error => {
      console.error("Erreur lors de l'ajout de la suggestion:", error);
    });
  };

  const handleDeleteSuggestion = (suggestionId) => {
    if (!cours.assistant_id || !suggestionId) {
      console.error("assistant_id ou suggestion_id manquant.");
      return;
    }

    fetch(serverPHP + "/db/delSuggestion.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        assistant_id: cours.id,
        suggestion_id: suggestionId
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        // Supprimer la suggestion du tableau
        setCours(prevCours => ({
          ...prevCours,
          suggestions: prevCours.suggestions.filter(suggestion => suggestion.id !== suggestionId)
        }));
      } else {
        console.error("Erreur lors de la suppression de la suggestion:", data.message);
      }
    })
    .catch(error => {
      console.error("Erreur lors de la suppression de la suggestion:", error);
    });
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <div className="options-menu" style={{ gap: '80px' }}>
          <div
            className={`option-item ${selectedTab === 'instructions' ? 'selected' : ''}`}
            onClick={() => setSelectedTab('instructions')}
          >
            <InstructionsIcon alt="Instructions" className="tab-icon" />
            <span>Instructions</span>
            {selectedTab === 'instructions' && <div className="option-underline"></div>}
          </div>
          <div
            className={`option-item ${selectedTab === 'accueil' ? 'selected' : ''}`}
            onClick={() => setSelectedTab('accueil')}
          >
            <AccueilIcon alt="Message d'accueil" className="tab-icon" />
            <span>Accroche</span>
            {selectedTab === 'accueil' && <div className="option-underline"></div>}
          </div>
          <div
            className={`option-item ${selectedTab === 'suggestions' ? 'selected' : ''}`}
            onClick={() => setSelectedTab('suggestions')}
            style={{position:'relative'}}
          >
            {cours.suggestions.length > 0 &&
              <div className="nbsuggestions">{cours.suggestions.length}</div>
            }
            <SuggestionsIcon alt="Suggestions" className="tab-icon" />
            <span>Suggestions</span>
            {selectedTab === 'suggestions' && <div className="option-underline"></div>}
          </div>
        </div>

        <div className="modal-content-area">
          {selectedTab === 'instructions' ? (
            <>
              <textarea
                value={localInstructions || ''}
                onChange={(e) => setLocalInstructions(e.target.value)}
                className="modal-textarea"
                placeholder="Saisissez les instructions ici"
              />
              <div className="infos">
                Les instructions sont <strong>l'identité</strong> de votre chatbot : elles décrivent
                qui il est, ce qu'il doit dire et faire. Il est fortement conseillé de demander au
                bot de <strong>refuser de répondre</strong> à toute question qui ne
                concerne pas le cours.
              </div>
            </>
          ) : selectedTab === 'accueil' ? (
            <>
              <textarea
                value={localAccroche || ''}
                onChange={(e) => setLocalAccroche(e.target.value)}
                className="modal-textarea"
                placeholder="Saisissez le message d'accueil ici"
              />
              <div className="infos">
                L'<strong>accroche</strong> est le premier message qui sera affiché à l'utilisateur
                quand il commencera une conversation avec le chatbot. Vous pouvez utiliser la <strong>syntaxe MarkDown</strong> pour votre message : écrivez *ainsi* pour l'italique, et **ainsi** pour le gras. Vous pouvez laisser ce champ
                vide si vous ne voulez pas de message d'accueil.
              </div>
            </>
          ) : selectedTab === 'suggestions' ? (
            <div className="suggestions-tab">
              <div className="add-suggestion-container">
                <input
                  type="text"
                  value={newSuggestion}
                  onChange={(e) => {
                    if (e.target.value.length <= 500) {
                      setNewSuggestion(e.target.value);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && newSuggestion.trim() && newSuggestion.length <= 500) {
                      handleAddSuggestion();
                    }
                  }}
                  className="url-input"
                  placeholder="Votre suggestion ici..."
                />
                <div
                  className={`login-button  ${newSuggestion.trim() && newSuggestion.length <= 500 ? '' : 'disabled'}`}
                  onClick={() => {
                    if (newSuggestion.trim() && newSuggestion.length <= 500) {
                      handleAddSuggestion();
                    }
                  }}
                  style={{ cursor: newSuggestion.trim() && newSuggestion.length <= 500 ? 'pointer' : 'default', fontWeight:'normal', height:'40px', alignContent:'center', padding:0, width:'80px', textAlign:'center' }}
                >
                  Ajouter
                </div>
              </div>
              <div className="suggestions-list" ref={suggestionsListRef}>
  {(cours.suggestions || []).slice().reverse().map(suggestion => (
    <div key={suggestion.id} className="suggestion-item">
      <span className="suggestion-text">{suggestion.suggestion}</span>
      <span
        className="delete-suggestion"
        onClick={() => handleDeleteSuggestion(suggestion.id)}
        title="Supprimer la suggestion"
      >
        <PicsDelete className="deleteIcon" />
      </span>
    </div>
  ))}
  {cours.suggestions.length === 0 && (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'flex-start', height: '100%' }}>
      <div className="infos" style={{ alignSelf: 'center' }}>
        Les <strong>questions</strong> que vous ajoutez ici seront suggérées à l'utilisateur.
      </div>
    </div>
  )}
</div>
            </div>
          ) : null}
        </div>
        <div className="modal-div-boutons">
          <button onClick={reset} className="modal-btn" style={{ backgroundColor: '#c6c6c6' }}>
            Réinitialiser
          </button>
          <button onClick={closeAndSave} className="login-button" style={{width:'150px'}}>
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
