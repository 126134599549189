import "./ListAssistants.css";
import ProfileButton from "./ProfileButton";
import { ReactComponent as PicsSettings } from "../pics/settings.svg";
import { ReactComponent as PicsSettingsOn } from "../pics/settings_on.svg";




const HeaderMainMenuProf = ({setUserInfo, userInfo, matieres, setShowModules, showModules}) => {

return (
<div className="bandeauHaut">
<div className="titreSujet" style={{margin:'20px 0 0 40px', color: 'var(--menu-text-color)'}}>Mes chatbots</div>
<div className="rightProfileButton" style={{marginTop:'5px', gap:'10px'}}>
<div className={`boutonSVG ${showModules ? 'showModules' : 'dontShowModules'}`} onClick={() => {setShowModules(!showModules)}}>
            {showModules ?
            <PicsSettingsOn width={20} height={20} />
            :
          <PicsSettings width={20} height={20} />
            }
          Voir les modules
        </div>
    <ProfileButton userInfo={userInfo} setUserInfo={setUserInfo} matieres={matieres}/>
</div>
</div>
)

}

export default HeaderMainMenuProf;