// ModifyForm.jsx

import React, { useState, useEffect, useRef } from 'react';
import './AccountForm.css';

const ModifyForm = ({ userInfo, nomAffiche, setNomAffiche, matieres, selectedMatieres, setSelectedMatieres, setShake }) => {
    const [info, setInfo] = useState(null);
    const [showAccessModal, setShowAccessModal] = useState(false);

    const [usernameError, setUsernameError] = useState(false);

    // State variables for matieres
    const [matiereInput, setMatiereInput] = useState('');
    const [showMatiereSuggestions, setShowMatiereSuggestions] = useState(false);

    const [isSelectingSuggestion, setIsSelectingSuggestion] = useState(false);

    const matiereInputRef = useRef(null);


    useEffect(() => {
        if (info !== null) {
            setShowAccessModal(true);
        }
    }, [info]);


    const triggerShake = () => {
        setShake(true);
        setTimeout(() => setShake(false), 500);
    };

    // On mount, set initial values
    useEffect(() => {
        const isTeacher = userInfo.prof === 1;
        if (isTeacher) {
            setSelectedMatieres(userInfo.matieres || []);
        }
    }, [userInfo]);



    const handleUsernameChange = (e) => {
        const value = e.target.value;
        setNomAffiche(value);
        if (value.length > 40 || !value) {
            setUsernameError(true);
        } else {
            setUsernameError(false);
        }
    };

    // Functions for matieres selection
    const addMatiere = (matiere) => {
        if (!selectedMatieres.some(m => m.id === matiere.id)) {
            setSelectedMatieres([...selectedMatieres, matiere]);
        }
        setMatiereInput('');
        setShowMatiereSuggestions(false);
        if (matiereInputRef.current) {
            matiereInputRef.current.focus();
        }
    };

    const removeMatiere = (id) => {
        setSelectedMatieres(selectedMatieres.filter(m => m.id !== id));
    };

    const handleMatiereInputChange = (e) => {
        setMatiereInput(e.target.value);
        setShowMatiereSuggestions(true);
    };

    const filteredMatieres = matieres
        .filter(matiere => {
            const searchText = matiereInput.toLowerCase();
            return (
                (matiere.nom_long.toLowerCase().includes(searchText) || matiere.nom_court.toLowerCase().includes(searchText)) &&
                !selectedMatieres.some(selected => selected.id === matiere.id)
            );
        })
        .sort((a, b) => {
            const searchText = matiereInput.toLowerCase();
            const aStartsWith = a.nom_long.toLowerCase().startsWith(searchText) || a.nom_court.toLowerCase().startsWith(searchText);
            const bStartsWith = b.nom_long.toLowerCase().startsWith(searchText) || b.nom_court.toLowerCase().startsWith(searchText);

            if (aStartsWith && !bStartsWith) return -1;
            if (!aStartsWith && bStartsWith) return 1;

            // Prioritize exact matches
            const aExactMatch = a.nom_long.toLowerCase() === searchText || a.nom_court.toLowerCase() === searchText;
            const bExactMatch = b.nom_long.toLowerCase() === searchText || b.nom_court.toLowerCase() === searchText;

            if (aExactMatch && !bExactMatch) return -1;
            if (!aExactMatch && bExactMatch) return 1;

            return 0;
        });

    const handleMatiereInputBlur = () => {
        setTimeout(() => {
            if (isSelectingSuggestion) {
                // Do nothing if a suggestion is being selected
                setIsSelectingSuggestion(false);
                return;
            }
            if (matiereInput !== "") {
                setInfo(<><p>Vous devez valider chaque matière enseignée <strong>dans la liste déroulante</strong>.</p>
                    <p>Si vous ne trouvez pas votre matière, veuillez choisir <strong>"Autre"</strong> ; contactez l'assistance de l'application pour suggérer un ajout.</p></>);
                setMatiereInput("");
                setShowMatiereSuggestions(false);
            } else {
                setShowMatiereSuggestions(false);
            }
        }, 200);
    };

    

    const handleClickOutsideModal = (e) => {
        if (e.target === e.currentTarget) {
            setShowAccessModal(false);
        }
    };



    return (
        <>


                <div className="input-group">
                    <label htmlFor="username" className="login-label">Nom affiché :</label>
                    <input
                        type="text"
                        id="username"
                        className={`login-input ${usernameError ? 'input-error' : ''}`}
                        value={nomAffiche}
                        onChange={handleUsernameChange}
                    />
                </div>

                {userInfo.prof === 1 && (
                    <div className="input-group">
                        <label htmlFor="matiere" className="login-label">Matière(s) :</label>
                        <div className="matiere-input-container">
                            {selectedMatieres.map(matiere => (
                                <div key={matiere.id} className="selected-matiere">
                                    {matiere.nom_court}
                                    <span className="remove-matiere" onClick={() => removeMatiere(matiere.id)}>×</span>
                                </div>
                            ))}
                            <input
                                type="text"
                                id="matiere"
                                className="matiere-input"
                                value={matiereInput}
                                onChange={handleMatiereInputChange}
                                onFocus={() => setShowMatiereSuggestions(true)}
                                onBlur={handleMatiereInputBlur}
                                ref={matiereInputRef}
                                autoComplete="off"
                            />
                            {showMatiereSuggestions && (
                                <ul className="matiere-suggestions">
                                    {filteredMatieres.map((matiere) => (
                                        <li key={matiere.id}
                                            onMouseDown={() => setIsSelectingSuggestion(true)}
                                            onClick={() => {
                                                addMatiere(matiere);
                                                setIsSelectingSuggestion(false);
                                            }}>
                                            {matiere.nom_long}
                                        </li>
                                    ))}
                                    <li
                                        style={{ fontWeight: 'bold' }}
                                        onMouseDown={() => setIsSelectingSuggestion(true)}
                                        onClick={() => {
                                            addMatiere({ id: -1, nom_long: 'Autre', nom_court: 'Autre' });
                                            setIsSelectingSuggestion(false);
                                        }}
                                    >
                                        Autre
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                )}



            {showAccessModal && (
                <div className="modal-overlay-login" onClick={handleClickOutsideModal}>
                    <div className="modal-content-access" style={{ width: '450px' }}>
                        {info}
                    </div>
                </div>
            )}
        </>
    );
};

export default ModifyForm;
