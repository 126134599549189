// addSourceFunctions.js

import { serverPHP } from '../consts';

export const handleAddSource = async ({ type, data, nomNote }, cours, setCours, setError, processUploadResponse, PHPfile, fileIsSource) => {
  console.log(type);
  if (type === 'file') await handleAddFile(data, cours, setCours, setError, processUploadResponse, PHPfile, fileIsSource);
  else if (type === 'url') await handleAddUrl(data, cours, setCours, setError, processUploadResponse, PHPfile, fileIsSource);
  else if (type === 'text') await handleAddText(data, cours, setCours, setError, processUploadResponse, PHPfile, fileIsSource);
};

// Ajout de fichier
export const handleAddFile = async ({ file, filename }, cours, setCours, setError, processUploadResponse, PHPfile, fileIsSource) => {
  if (!file || !cours || cours.id == null) {
    setError("Aucun fichier sélectionné ou ID du cours non défini.");
    return;
  }

  console.log("on passe par handleAddFile")
  const newFile = { id: null, filename, statut: 1, messageErreur: null };
  setCours((prevCours) => ({ ...prevCours, files: [...prevCours.files, newFile] })); //le cas échéant, on a passé un setCours vide : cette instruction ne fait rien

  const formData = new FormData();
  formData.append('file', file, filename);
  formData.append('coursId', cours.id);
  formData.append('filename', filename);
  formData.append('fileIsSource', fileIsSource);

  try {
    console.log("serverPHP + PHPfile", serverPHP + PHPfile)
    const response = await fetch(serverPHP + PHPfile, { method: 'POST', body: formData });
    const result = await response.json();
    console.log("result", result)
    processUploadResponse(response, result, filename);
  } catch (err) {
    console.error("Erreur lors de l'envoi du fichier", err);
    setError("Erreur lors de l'envoi du fichier");
  }
};

// Ajout d'URL
export const handleAddUrl = async ({ url, filename }, cours, setCours, setError, processUploadResponse, PHPfile, fileIsSource) => {
  if (!url || !cours || cours.id == null) {
    setError("URL invalide ou ID du cours non défini.");
    return;
  }

  const newFile = { id: null, filename, statut: 1, messageErreur: null };
  setCours((prevCours) => ({ ...prevCours, files: [...prevCours.files, newFile] }));

  const formData = new FormData();
  formData.append('url', url);
  formData.append('filename', filename);
  formData.append('coursId', cours.id);
  formData.append('fileIsSource', fileIsSource);


  try {
    const response = await fetch(serverPHP + PHPfile, { method: 'POST', body: formData });
    const result = await response.json();
    processUploadResponse(response, result, filename);
  } catch (err) {
    console.error("Erreur lors de l'envoi de l'URL", err);
    setError("Erreur lors de l'envoi de l'URL");
  }
};

// Ajout de texte
export const handleAddText = async ({ text, filename }, cours, setCours, setError, processUploadResponse, PHPfile, fileIsSource) => {
  if (!text || !filename || !cours || cours.id == null) {
    setError("Texte, nom de note ou ID du cours manquant.");
    return;
  }

  const newFile = { id: null, filename, statut: 1, messageErreur: null };
  setCours((prevCours) => ({ ...prevCours, files: [...prevCours.files, newFile] }));

  const formData = new FormData();
  formData.append('text', text);
  formData.append('filename', filename);
  formData.append('coursId', cours.id);
  formData.append('fileIsSource', fileIsSource);


  try {
    const response = await fetch(serverPHP + PHPfile, { method: 'POST', body: formData });
    const result = await response.json();
    processUploadResponse(response, result, filename);
  } catch (err) {
    console.error("Erreur lors de l'envoi de la note", err);
    setError("Erreur lors de l'envoi de la note");
  }
};
