import React, { useState, useEffect, useRef } from 'react';
import { serverPHP } from '../../consts';
import './ModuleQCM_EditQCM.css'; // Assuming you create a CSS file for specific styles
import { ReactComponent as PicsDelete } from "../../pics/delete.svg";
import { ReactComponent as PicsCheck } from "../../pics/check_icon.svg";
import { ReactComponent as PicsChevronGauche } from "../../pics/chevron_gauche.svg";
import { ReactComponent as PicsChevronDroite } from "../../pics/chevron_droite.svg";




const ModuleQCM_EditQCM = ({ qcm, setQcm, setCurrentQCM, backId, nextId, setCours }) => {
    const [localQcm, setLocalQcm] = useState(qcm);
        const topRef = useRef(null);


    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
                setLocalQcm(qcm);
    }, [qcm]);

    const updateQuestion = () => {
        setQcm(localQcm);
        fetch(`${serverPHP}/db/moduleQCM_modifyQuestion.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                question_id: localQcm.question_id,
                question: localQcm.question,
            }),
        })
            .then((response) => response.json())
            .catch((error) => console.error('Error updating question:', error));
    };

    const deleteQCM = () => {
        fetch(`${serverPHP}/db/moduleQCM_delQCM.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ question_id: localQcm.question_id }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    //alert('QCM supprimé avec succès');
                    // Optionally, you could navigate to a different QCM or reset state here
                    setCours((prevCours) => ({
                        ...prevCours,
                        modules: {
                            ...prevCours.modules,
                            qcm: prevCours.modules.qcm.filter(
                                (q) => q.question_id !== localQcm.question_id
                            ),
                        },
                    }));
                    if (nextId !== null) setCurrentQCM(nextId); // Example: Set to null or redirect to another QCM
                } else {
                    console.error('Error deleting QCM:', data.error);
                }
            })
            .catch((error) => console.error('Error deleting QCM:', error));
    };

    const updateAnswer = (updatedAnswer) => {
        fetch(`${serverPHP}/db/moduleQCM_modifyAnswer.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                reponse_id: updatedAnswer.reponse_id,
                reponse: updatedAnswer.text,
                feedback: updatedAnswer.feedback,
                bonnereponse: updatedAnswer.bonne_reponse ? 1 : 0,
                question_id: localQcm.question_id,
            }),
        })
            .then((response) => response.json())
            .catch((error) => console.error('Error updating answer:', error));
    };

    const deleteAnswer = (index) => {
        const answerToDelete = localQcm.answers[index];
        if (localQcm.answers.length <= 3) return;

        fetch(`${serverPHP}/db/moduleQCM_deleteAnswer.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ reponse_id: answerToDelete.reponse_id }),
        })
            .then((response) => response.json())
            .then(() => {
                const updatedAnswers = localQcm.answers.filter((_, idx) => idx !== index);
                const updatedQcm = { ...localQcm, answers: updatedAnswers };
                setLocalQcm(updatedQcm);
                setQcm(updatedQcm);
            })
            .catch((error) => console.error('Error deleting answer:', error));
    };

    const addAnswer = () => {
        fetch(`${serverPHP}/db/moduleQCM_addAnswer.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ question_id: localQcm.question_id }),
        })
            .then((response) => response.json())
            .then((data) => {
                const newAnswer = {
                    reponse_id: data.reponse_id,
                    text: 'Nouvelle réponse',
                    feedback: '',
                    bonne_reponse: false,
                };
                const updatedAnswers = [...localQcm.answers, newAnswer];
                const updatedQcm = { ...localQcm, answers: updatedAnswers };
                setLocalQcm(updatedQcm);
                setQcm(updatedQcm);
            })
            .catch((error) => console.error('Error adding new answer:', error));
    };

    const handleAnswerChange = (index, field, value) => {
        const updatedAnswers = [...localQcm.answers];
        updatedAnswers[index] = { ...updatedAnswers[index], [field]: value };
        setLocalQcm({ ...localQcm, answers: updatedAnswers });
    };

    const handleAnswerBlur = (index) => {
        const answer = localQcm.answers[index];
        let updatedAnswer = { ...answer };
        if (answer.text.trim() === '') {
            updatedAnswer.text = 'Nouvelle réponse';
            handleAnswerChange(index, 'text', 'Nouvelle réponse');
        }
        updateAnswer(updatedAnswer);
        setQcm(localQcm);
    };

    const handleBonneReponseChange = (index) => {
        const updatedAnswers = localQcm.answers.map((answer, idx) => ({
            ...answer,
            bonne_reponse: idx === index,
        }));
        const updatedQcm = { ...localQcm, answers: updatedAnswers };
        setLocalQcm(updatedQcm);
        setQcm(updatedQcm);

        updatedAnswers.forEach((answer) => {
            updateAnswer(answer);
        });
    };

    return (
        <div className="moduleMain"  ref={topRef} >
            <div className="moduleContainer">
                {/* Question TextArea */}
                <div className="moduleTitre" style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={{width:'110px'}}>
                    {backId !== null ? <><span onClick={() => setCurrentQCM(backId)} className="chevronQCM"> <PicsChevronGauche /> Précédent</span></> : <></>}
                    </div>
                    <div>
                        <button className="login-button"  onClick={deleteQCM}>Supprimer ce QCM</button>
                    </div>
                    <div style={{width:'110px'}}>
                    {nextId !== null ? <><span onClick={() => setCurrentQCM(nextId)} className="chevronQCM" style={{justifyContent: 'flex-end'}}> Suivant <PicsChevronDroite /></span></> : <></>}
                    </div>
                </div>
                <div className="partageItem itemEdit">
                    <span style={{marginBottom:'20px'}}>Question :</span><textarea
                        className="responseTextArea"
                        value={localQcm.question}
                        onChange={(e) => setLocalQcm({ ...localQcm, question: e.target.value })}
                        onBlur={updateQuestion}
                        placeholder="Enter your question here"
                    />
                </div>

                {/* Answers */}
                {localQcm.answers.map((answer, index) => (
                    <div key={answer.reponse_id} className="partageItem itemEdit marginReponse">
                        <div className="titreReponse">
                            <span className={answer.bonne_reponse ? "label_bonnereponse" : ""}>- Réponse {index + 1} :</span>
                            <div>
                                {/* Bonne Réponse Radio Button */}
                                <div className={answer.bonne_reponse ? "" : "checkHover"} style={{    marginRight:'5px'}}>
                                <PicsCheck className={`checkIcon ${answer.bonne_reponse ? "activeCheck" : "disabledCheck"}`}
                                    onClick={() => { if (!answer.bonne_reponse) handleBonneReponseChange(index) }} />
                                    </div>
                                {/* Delete Answer Button */}
                                {localQcm.answers.length > 3 && (
                                    <PicsDelete onClick={() => deleteAnswer(index)} className="deleteIcon" />
                                )}
                            </div>
                        </div>
                        {/* Answer TextArea */}
                        <textarea
                            className="responseTextArea"
                            value={answer.text}
                            onChange={(e) => handleAnswerChange(index, 'text', e.target.value)}
                            onBlur={() => handleAnswerBlur(index)}
                            placeholder="Answer text"
                        />
                        <div className="feedback ts16px">Feedback (facultatif) :</div>
                        {/* Feedback TextArea */}
                            <textarea
                                className="responseTextArea"
                                value={answer.feedback}
                                onChange={(e) => handleAnswerChange(index, 'feedback', e.target.value)}
                                onBlur={() => handleAnswerBlur(index)}
                                placeholder="Laissez ce champ vide si vous ne voulez pas de feedback"
                            />
                    </div>
                ))}

<div className="partageItem itemEdit marginReponse" style={{alignItems:'center'}}>

                {/* Add Answer Button */}
                <button onClick={addAnswer} className="login-button">
                    + Ajouter une réponse
                </button>
                </div>
            </div>
        </div>
    );
};

export default ModuleQCM_EditQCM;
