import { useState, useEffect } from 'react';
import { ReactComponent as PicsTextfile } from "../../pics/textfile.svg";
import FileList from './FileList';

import AddSourceModal from '../AddSourceModal';
import { handleAddSource } from '../addSourceFunctions';
import LoadingCircle from '../LoadingCircle';
import { serverPHP } from '../../consts';

const Module_selectFile_2 = ({ cours, setContenuFichier, setTitreMenu, reset, fichierFromSources, sources, setFichierFromSources }) => {
    const [loading, setLoading] = useState(false);

    const processUploadResponse = (response, result, filename) => {
        setLoading(false);
        if (response.ok) {
            if (result.success && result.content) setContenuFichier(result.content);
            else throw new Error("Le contenu du fichier apparaît vide");
        } else {
            throw new Error("Le serveur a répondu d'une façon inappropriée");
        }
    };

    const handleSendSource = async (data) => { //quand c'est une source, on n'envoie pas le fichier : on envoie simplement le nom du fichier, et il le retrouve lui-même
        try {
            console.log("on envoie la source", data);
            setLoading(true);
            const formData = new FormData();
            formData.append('coursId', cours.id);
            formData.append('filename', data.filename);
            formData.append('fileIsSource', true);

            const response = await fetch(serverPHP + '/modules/handleTxtConversion.php', {
                method: 'POST',
                body: formData
            });
            console.log("on attend la réponse")
            const result = await response.json();
            console.log("on a reçu la réponse")
            processUploadResponse(response, result, data.filename);
        } catch (error) {
            console.error("Erreur lors de l'envoi de la source:", error);
            setLoading(false);
        }
    };



    useEffect(() => {
        setTitreMenu("1. Sélection du fichier");
    }, [])



    return (
        <>           {
            loading ? (
                <div className="moduleContainer" style={{position:'relative'}}>

                <div style={{ height: '495px' }}>
                    <LoadingCircle taille={150} />
                </div>
                <div className="generationQCMEnCours">
                            Extraction du texte en cours...
                            <br /><span style={{ fontSize: '14px' }}>Cette opération peut prendre une vingtaine de secondes</span>

                        </div>
                                    </div >
                                


) : fichierFromSources === true ? (
    <div className="moduleContainer">

        <div className="moduleTitre">Choisissez le <strong>fichier source</strong></div>
        <FileList
            listeFichiers={sources}
            isSource={true}
            selectFile={true}
            image={<PicsTextfile />}
            onClickButton={(data) => handleSendSource(data)}
            hoverAll={true}
            filterTxt={true}
        />
    </div>
) : (
    <div className="moduleContainer">

        <AddSourceModal
            isOpen={true}
            onClose={() => { }}
            onAddSource={(data) => {
                setLoading(true);
                handleAddSource(data, cours, () => { }, () => { }, processUploadResponse, '/modules/handleTxtConversion.php');
            }}
            cours={cours}
            wide={true}
        />
    </div>

)}
       </>
    );
};

export default Module_selectFile_2;
