// DropdownMenu.js

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import './DropdownMenu.css';

const DropdownMenu = ({ openMenu, setOpenMenu, buttonRef, menuItems, openDirection = 'left', decalageY = -1, decalageX = -1 }) => {
  const menuRef = useRef(null);
  const [menuStyle, setMenuStyle] = useState({});

  const handleClickOutside = useCallback((event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef?.current &&  
      !buttonRef.current.contains(event.target)
    ) {
      setOpenMenu();  // Appelle setOpenMenu sans arguments pour fermer le menu
    }
  }, [setOpenMenu, buttonRef]);

  useEffect(() => {
    const updateMenuPosition = () => {
      if (buttonRef?.current) { 
        const rect = buttonRef.current.getBoundingClientRect();
        const top = 
          openDirection === 'left'
            ? rect.bottom + window.scrollY + (decalageY === -1 ? 0 : decalageY)
            : rect.bottom + window.scrollY - 10;

        const left =
          openDirection === 'left'
            ? rect.left + window.scrollX + (decalageX === -1 ? -180 : decalageX)
            : rect.left + window.scrollX + 20;

        const transformOrigin =
          openDirection === 'left'
            ? 'top right' : 'top left';

        setMenuStyle({
          top: top,
          left: left,
          transformOrigin: transformOrigin
        });
      }
    };

    if (openMenu) {
      updateMenuPosition();
      window.addEventListener('resize', updateMenuPosition);
    }

    return () => {
      window.removeEventListener('resize', updateMenuPosition);
    };
  }, [openMenu, buttonRef, openDirection, decalageX, decalageY]);

  useEffect(() => {
    if (openMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openMenu, handleClickOutside]);

  const menuPortalElement =
    document.getElementById('menu-portal') || document.createElement('div');
  if (!menuPortalElement.id) {
    menuPortalElement.id = 'menu-portal';
    document.body.appendChild(menuPortalElement);
  }

  return createPortal(
    <div
      className={`menu ${openMenu ? 'displayMenu' : 'hideMenu'}`}
      style={menuStyle}
      ref={menuRef}
    >
      {menuItems.map((item, index) => (
        <div
          key={index}
          className="menu-item"
          onClick={(e) => {
            e.stopPropagation();
            setOpenMenu();  // Appelle setOpenMenu sans arguments pour fermer le menu
            item.onClick();
          }}
        >
          {item.label}
        </div>
      ))}
    </div>,
    menuPortalElement
  );
};

export default DropdownMenu;