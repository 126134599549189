import ProfileButton from "./ProfileButton";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { serverPHP } from "../consts";
import "./ListeMenuEleve.css";
import { ReactComponent as PicsFleche } from "../pics/fleche.svg";
import { modules_full } from '../consts';
import { useNavigate } from 'react-router';


const ListeMenuEleve = ({ userInfo, setUserInfo, groupes, setRefresh, loading, groupe_id, currentGroupe }) => {
    const [isAddingGroup, setIsAddingGroup] = useState(false);
    const [groupCode, setGroupCode] = useState('');
    const addGroupRef = useRef(null);
    const navigate = useNavigate();

    

    const showAddGroup = () => {
        setIsAddingGroup(true);
    };

    const handleAddGroup = async () => {
        try {
            const response = await fetch(serverPHP + "/db/addUserToGroup.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ user_id: userInfo.id, code: groupCode }),
            });

            const data = await response.json();

            if (data.error) {
                alert(data.error);
            } else {
                // Success, refresh the groups
                setRefresh(true);
                setIsAddingGroup(false);
                setGroupCode('');
            }
        } catch (error) {
            console.error("Erreur lors de l'ajout du groupe :", error);
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                isAddingGroup &&
                addGroupRef.current &&
                !addGroupRef.current.contains(event.target)
            ) {
                setIsAddingGroup(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isAddingGroup]);

    return (
        <>
            <div className="bandeauHaut">
                <div className="titreHaut">
                    {(currentGroupe === null || currentGroupe === undefined) ?
                        <>
                            <span style={{ fontWeight: 'bold', fontSize: 'inherit' }}>EduBot</span>&nbsp; - Mes cours
                        </> :
                        <div style={{ fontSize: 'inherit', display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }} onClick={() => navigate("/")}>
                            <PicsFleche width={30} height={30} alt="Retour" />
                            Retour à la liste
                        </div>
                    }
                </div>
                <div className="rightProfileButton">
                    <ProfileButton setUserInfo={setUserInfo} userInfo={userInfo} />
                </div>
            </div>

            {/* New Assistant Card */}
            <div className="assistants-container" style={{ padding: '40px 80px' }}>
                {(currentGroupe === null) || (currentGroupe === undefined) ?
                    <>
                        {/* Existing Group Cards */}
                        {groupes.map((groupe, index) => (
                            <div
                                key={index}
                                className="assistant-card existing-card existing-cardHover fade-in"
                                style={{
                                    justifyContent: "space-between",
                                    animationDuration: '0.1s',
                                    animationDelay: `${index * 0.05}s`,
                                }}
                                onClick={() => {
                                    //setGroupe(groupe);
                                    navigate("/groupe/" + groupe.id);
                                }}
                            >
                                <div>
                                    <div className="carteGroupe">{groupe.matiere.nom_court}</div>
                                    <div
                                        style={{
                                            fontSize: '18px',
                                            textAlign: 'center',
                                            marginTop: '10px',
                                        }}
                                    >
                                        {groupe.auteur_nom}
                                    </div>
                                </div>
                                <div>
                                    {groupe.cours.length} Chatbot
                                    {groupe.cours.length > 1 && 's'}
                                </div>
                            </div>
                        ))}

                        {/* New Assistant Card */}
                        {!loading && (
                            <div
                                className="assistant-card new-assistant fade-in"
                                onClick={showAddGroup}
                                ref={addGroupRef}
                                style={{
                                    animationDuration: '0.1s',
                                    animationDelay: `${groupes.length * 0.05}s`,
                                }}
                            >
                                {/* Nouveau conteneur pour le scale de hover */}
                                <div className="new-assistant-inner">
                                    {isAddingGroup ? (
                                        <>
                                            <div className="assistant-title">Code du groupe :</div>
                                            <input
                                                type="text"
                                                className="login-input"
                                                value={groupCode}
                                                onChange={(e) => setGroupCode(e.target.value)}
                                            />
                                            <button
                                                onClick={handleAddGroup}
                                                className="login-button"
                                                style={{ marginTop: '20px', width: '100%' }}
                                            >
                                                Ajouter
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <div className="plus-sign">+</div>
                                            <div className="assistant-title">
                                                Ajouter
                                                <br />
                                                un groupe
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                    : <>
                        {currentGroupe.cours && currentGroupe.cours.length > 0 ? (
                            currentGroupe.cours.map((cours, index) => {
                                const modules = modules_full(cours);
                                return (
                                    <div
                                        key={index}
                                        className="assistant-card existing-card existing-cardHover fade-in"
                                        onClick={() => {
                                            console.log(cours.assistant_id);
                                            console.log(cours);
                                            navigate("/groupe/" + currentGroupe.id + "/chatbot/" +  cours.id);
                                        }}
                                        style={{
                                            justifyContent: 'space-between',
                                            animationDuration: '0.1s',
                                            animationDelay: `${index * 0.05}s`,
                                        }}
                                    >
                                        <div className="assistant-title">{cours.sujet}</div>
                                        {/* Nouveau div pour afficher les icônes */}
                                        <div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
                                        <div style={{display:'flex', gap:'10px', flexWrap: 'wrap'}}>
                                            {modules.filter(m => m.isVisible).map((m, i) => (
                                                <span key={i}>
                                                    {React.cloneElement(m.icon, { title: m.statut, className:"iconeModuleEleve" })}
                                                </span>
                                            ))}
                                        </div>
                                        <div className="nomGroupe">{new Date(cours.date).toLocaleDateString('fr-FR')}</div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div style={{ fontSize: '20px' }}>Aucun chatbot n'a encore été ajouté à ce cours.</div>
                        )}
                    </>}
            </div>
        </>
    );
}

export default ListeMenuEleve;
