import { useState, useEffect } from 'react';
import FileList from './FileList';
import { ReactComponent as PicsTextfile } from "../../pics/textfile.svg";
import './Modules.css';
import { ReactComponent as PicsImagefile } from "../../pics/imagefile.svg";
import { ReactComponent as PicsDelete } from "../../pics/delete.svg";

import { serverPHP } from '../../consts';
import Module_selectFile from './Module_selectFile';
import ModuleDiapo_parametres from './ModuleDiapo_parametres';
import ModuleDiapo_ShowDiapo from './ModuleDiapo_ShowDiapo';

const ModuleDiapo = ({ cours, setCours, setTitreMenu, reset, setReset }) => {
    const [contenuFichier, setContenuFichier] = useState(null);
    const [fichierFromSources, setFichierFromSources] = useState(null);
    const [currentDiapo, setCurrentDiapo] = useState(null);

    useEffect(() => {
        if (reset) {
            setContenuFichier(null);
            setReset(false);
            setFichierFromSources(null);
        }
    }, [reset]);

    const handleDelete = async (file) => {
        // Update the state to remove the selected file
        setCours((prevCours) => ({
            ...prevCours,
            modules: {
                ...prevCours.modules,
                diapo: prevCours.modules.diapo.filter((item) => item.mindmap_id !== file.mindmap_id)
            }
        }));

        // Send the delete request to the server
        try {
            await fetch(`${serverPHP}/db/moduleDiapo_delDiapo.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    assistant_id: cours.id,
                    diapo_id: file.diapo_id
                })
            });
        } catch (error) {
            console.error('Error deleting mindmap:', error);
        }
    };

    // Fonction pour supprimer toutes les diapos
    const handleDeleteAll = async () => {
        setCours((prevCours) => ({
            ...prevCours,
            modules: {
                ...prevCours.modules,
                diapo: []
            }
        }));

        try {
            await fetch(`${serverPHP}/db/moduleDiapo_delAllDiapos.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    assistant_id: cours.id
                })
            });

        } catch (error) {
            console.error('Error deleting all diapos:', error);
        }
    };

    return (
        <div className="moduleMain">
            {contenuFichier === null ?
                <>
                    <Module_selectFile
                        cours={cours}
                        reset={reset}
                        setReset={setReset}
                        setContenuFichier={setContenuFichier}
                        setTitreMenu={setTitreMenu}
                        navigationSelectFile={fichierFromSources}
                        setNavigationSelectFile={setFichierFromSources}
                        titreSelection={<><strong>Générer</strong> des diapos</>}
                    />
                    {(fichierFromSources === null) &&
                        <div className="moduleContainer">
                            <div className="moduleTitre">Diaporamas <strong>ajoutés</strong></div>
                            <div className="signup-choice" style={{ margin: '0 0 20px 0', width: '100%' }}>
                                <div style={{ width: '100%' }}>
                                    <div className="containerFichiersPartage">
                                        {cours.modules.diapo.map((file, index) => (
                                            <div
                                                key={index}
                                                className="partageItem partageHover"
                                                style={{ justifyContent: "space-between", cursor: 'pointer' }}
                                                onClick={() => setCurrentDiapo(file)}
                                            >
                                                <div>
                                                    <div className="actionButton">
                                                        <PicsImagefile />
                                                    </div>
                                                    <span>{file.nomFichier}</span>
                                                </div>
                                                <div
                                                    className="deleteButtonSVG"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDelete(file);
                                                    }}
                                                >
                                                    <PicsDelete />
                                                </div>
                                            </div>
                                        ))}

                                        {cours.modules.diapo.length === 0 &&
                                            <div style={{ width: '100%' }}><div className="containerFichiersPartage"></div>
                                                <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
                                                    Vous n'avez encore généré aucun diaporama.</p></div>
                                        }
                                    </div>
                                </div>
                                {cours.modules.diapo.length > 0 &&
                                    <button
                                        className="login-button"
                                        style={{ width: '200px', margin: 'auto', marginTop: '-20px' }}
                                        onClick={handleDeleteAll}
                                    >
                                        Tout supprimer
                                    </button>
                                }
                            </div>
                        </div>
                    }
                    {currentDiapo &&
                        <ModuleDiapo_ShowDiapo
                            currentDiapo={currentDiapo}
                            setCurrentDiapo={setCurrentDiapo}
                        />
                    }
                </>
                :
                <ModuleDiapo_parametres
                    contenuFichier={contenuFichier}
                    setContenuFichier={setContenuFichier}
                    setTitreMenu={setTitreMenu}
                    cours={cours}
                    setCours={setCours}
                    setFichierFromSources={setFichierFromSources}
                    setCurrentDiapo={setCurrentDiapo}
                />
            }

        </div>
    )

}

export default ModuleDiapo;