import { useState, useEffect } from 'react';

import './Modules.css';
import Module_selectFile from './Module_selectFile';
import ModuleAudio_parametres from './ModuleAudio_parametres.js';

import ModuleAudio_ListeAudios from './ModuleAudio_ListeAudios.js';
import { serverPHP } from '../../consts/index.js';



const ModuleAudio = ({ cours, setCours, setTitreMenu, reset, setReset }) => {
    const [contenuFichier, setContenuFichier] = useState(null);
    const [fichierFromSources, setFichierFromSources] = useState(null);
    const [currentAudioFile, setCurrentAudioFile] = useState(null);

    useEffect(() => {
        if (reset) {
            setContenuFichier(null);
            setReset(false);
            setFichierFromSources(null);
        }
    }, [reset]);

    const handleDelete = async (file) => {
        // Update the state to remove the selected file
        setCours((prevCours) => ({
            ...prevCours,
            modules: {
                ...prevCours.modules,
                audio: prevCours.modules.audio.filter((item) => item.filename !== file.filename)
            }
        }));

        // Send the delete request to the server
        try {
            await fetch(`${serverPHP}/db/moduleAudio_delAudio.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    coursId: cours.id,
                    filename: file.filename
                })
            });
        } catch (error) {
            console.error('Error deleting audio:', error);
        }
        
    };

    // Function to delete all audios
    const handleDeleteAll = async () => {
        setCours((prevCours) => ({
            ...prevCours,
            modules: {
                ...prevCours.modules,
                audio: []
            }
        }));


        try {
            await fetch(`${serverPHP}/db/moduleAudio_delAllAudio.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    coursId: cours.id
                })
            });
        } catch (error) {
            console.error('Error deleting all audios:', error);
        }
    };

    return (
        <div className="moduleMain">
            {(contenuFichier === null) ?
                <>
                    <Module_selectFile
                        cours={cours}
                        reset={reset}
                        setReset={setReset}
                        setContenuFichier={setContenuFichier}
                        setTitreMenu={setTitreMenu}
                        navigationSelectFile={fichierFromSources}
                        setNavigationSelectFile={setFichierFromSources}
                        titreSelection={<><strong>Générer</strong> des audios</>}
                    />
                    {(fichierFromSources === null) &&
                        <div className="moduleContainer">
                            <div className="moduleTitre">Audios <strong>ajoutés</strong></div>
                            <div className="signup-choice" style={{ margin: '0 0 20px 0', width: '100%' }}>
                                <div style={{ width: '100%' }}>
                                    <ModuleAudio_ListeAudios
                                    cours={cours}
                                    currentAudioFile={currentAudioFile}
                                    setCurrentAudioFile={setCurrentAudioFile}
                                    handleDelete={handleDelete}
                                    />
                                </div>
                                {cours.modules.audio.length > 0 &&
                                    <button
                                        className="login-button"
                                        style={{ width: '200px', margin: 'auto', marginTop: '-20px' }}
                                        onClick={handleDeleteAll}
                                    >
                                        Tout supprimer
                                    </button>
                                }
                            </div>
                        </div>
                    }
                </>
                :
                <ModuleAudio_parametres
                    contenuFichier={contenuFichier}
                    setContenuFichier={setContenuFichier}
                    setTitreMenu={setTitreMenu}
                    cours={cours}
                    setCours={setCours}
                    setCurrentAudio={setCurrentAudioFile}
                    setFichierFromSources={setFichierFromSources}
                />
            }
        </div>
    );
};

export default ModuleAudio;
