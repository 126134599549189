import './AddModule.css';
import { useState, useEffect } from 'react';

import ModulePartage from './Modules/ModulePartage';
import { serverPHP } from "../consts";
import { ReactComponent as Croix } from "../chatbot/images/croix.svg";
import { ReactComponent as ChevronDroite } from "../chatbot/images/chevron_droite.svg";
import { modules_full } from '../consts';
import ModuleQCM_Prof from './Modules/ModuleQCM_Prof';
import Mindmap from './Modules/ModuleMindmap';
import ModuleMindmap from './Modules/ModuleMindmap';
import ModuleAudio from './Modules/ModuleAudio';
import ModuleDiapo from './Modules/ModuleDiapo';


const AddModule = ({ cours, setCours, setShowModules, moduleActif, setModuleActif, showSources, setShowSources, userInfo/*, moduleActif, setModuleActif*/ }) => {

    const [titreMenu, setTitreMenu] = useState(null);
    const [reset, setReset] = useState(false);




    const OptionModule = ({ img, titre, description }) => (
        <div className="module" onClick={() => setModuleActif(titre)}>
            {img}
            <div className="descriptionModule">
                <div className="titreModule">{titre}</div>
                <div>{description}</div>
            </div>
        </div>
    );

    const onClickMenu = () =>{
        setReset(true);
    }

    const modules = modules_full(cours);

    return (<>
        <div className="titreEtCroix" style={{ backgroundColor: 'white' }}>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span data-icon="x" className="fermerCroix" onClick={() => {
                        setShowModules(false);
                        setShowSources(false);
                    }} style={{ display: 'flex' }}>
                        <Croix height={24} width={24} />
                    </span>
                    {
                        moduleActif ?
                            <div className="titre" style={{ position: 'relative' }}>
                                <span onClick={() => setModuleActif(null)} className="menuModuleClickable">Modules</span>
                                
                                <ChevronDroite className="chevronModule" /> 
                                <span
                                className={`menuModule ${moduleActif === "QCM" && titreMenu !== null ? "menuModuleClickable" : ''}`}
                                onClick={() => {if (moduleActif === "QCM") onClickMenu()}}
                                >{moduleActif}</span>
                                { moduleActif === "QCM" ?
                                
                                    titreMenu ? <><ChevronDroite className="chevronModule" /> <span style={{ marginLeft: '25px' }}>{titreMenu}</span></> : <></>
                                :
                                <></>

                                }
                            </div>
                            : <>
                                <div className="titre" style={{ position: 'relative' }}>Modules</div>

                            </>
                    }

                </div>
                <div>
                    <></>
                </div>
            </div>
        </div>
        <div className="groupeAddModule">
    {moduleActif === null ? (
        <>
            {modules.map((module, index) => (
                <OptionModule
                    key={index} // Add this line
                    img={module.icon}
                    index={index}
                    titre={module.title}
                    description={module.description}
                />
            ))}
        </>
    ) : moduleActif === "Partage de fichiers" ? (
        <ModulePartage cours={cours} setCours={setCours} />
    ) : moduleActif === "QCM" ? (
        <ModuleQCM_Prof
            cours={cours}
            setCours={setCours}
            setTitreMenu={setTitreMenu}
            reset={reset}
            setReset={setReset}
        />
    ) : moduleActif === "Carte mentale" ? (
        <ModuleMindmap
        cours={cours}
        setCours={setCours}
        setTitreMenu={setTitreMenu}
        reset={reset}
        setReset={setReset}
        />
    ) : moduleActif === "Audios" ? (
        <ModuleAudio
        cours={cours}
        setCours={setCours}
        setTitreMenu={setTitreMenu}
        reset={reset}
        setReset={setReset}
        />
    ) : moduleActif === "Diaporama" ? (
        <ModuleDiapo
        cours={cours}
        setCours={setCours}
        setTitreMenu={setTitreMenu}
        reset={reset}
        setReset={setReset}
        />
    ) : <></>    }
</div>

    </>
    );
};

export default AddModule;
