import {useEffect} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import { useLocation } from 'react-router-dom';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


function AppRouter() {
  return (
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<App/>} />
          <Route path="/confirm/:id" element={<App  menu="confirm" />} />
          <Route path="/inscription/" element={<App  menu="inscription" />} />
          <Route path="/chatbot/:id" element={<App  menu="chatbot" />} />
          <Route path="/groupe/:id" element={<App  menu="groupe" />} />
          <Route path="/groupe/:id/chatbot/:id2" element={<App  menu="groupe_et_chatbot" />} />

       </Routes>
      </BrowserRouter>
      
  );
}

export default AppRouter;
