import React, { useState, useEffect } from 'react';
import './ColorGrid.css';

const topColors = ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB'];
const bottomColors = ['#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB'];
const allColors = [...topColors, ...bottomColors];

const ColorGrid = ({ setColor }) => {
  // Tirer au sort une couleur par défaut lors du montage
  const [selectedColor, setSelectedColor] = useState(null);

  useEffect(() => {
    const randomColor = allColors[Math.floor(Math.random() * allColors.length)];
    setSelectedColor(randomColor);
    setColor(randomColor); // Met à jour la couleur au montage
  }, [setColor]); // Retirez `allColors` du tableau de dépendances si vous l'aviez

  const handleClick = (color) => {
    setSelectedColor(color); // Met à jour l'état local pour refléter la couleur sélectionnée
    setColor(color); // Appelle la fonction passée en prop pour mettre à jour la couleur globalement
  };

  return (
    <div className="color-grid">
      {allColors.map((color, index) => (
        <div
          key={index}
          onClick={() => handleClick(color)}
          className={`color-square ${selectedColor === color ? 'selected' : ''}`}
          style={{ backgroundColor: color }}
        />
      ))}
    </div>
  );
};

export default ColorGrid;
