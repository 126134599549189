import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import '../composants/ComposantInputBar.css';
import '../composants/Modules/Modules.css'
import { ReactComponent as SuggestionsButton } from "./images/suggestions.svg";



const Suggestions = ({ onSendMessage, handleSend, suggestions, questionsQCM }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false); // Manage animation state

    const popupRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setShowPopup(false);
                setIsAnimating(true);
            }
        };

        const handleResize = () => {
            if (showPopup) {
                setShowPopup(false);
                setIsAnimating(true);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('resize', handleResize);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('resize', handleResize);
        };
    }, [showPopup]);

    // Helper function to calculate button position
    const calculatePosition = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            const right = window.innerWidth - rect.right; // Distance entre le bord droit de l'élément et le bord droit de la fenêtre
            const bottom = window.innerHeight - rect.bottom + 50; // Distance entre le bord inférieur de l'élément et le bord inférieur de la fenêtre
            return { bottom, right, width: rect.width };
        }
        return { bottom: 0, right: 0 };
    };
    

    useEffect(() => {
        if (!showPopup && isAnimating) {
            const timeout = setTimeout(() => {
                setIsAnimating(false);
            }, 300); // Duration of the collapse animation

            return () => clearTimeout(timeout);
        }
    }, [showPopup, isAnimating]);

    const handleButtonClick = () => {
        if (showPopup) {
            setShowPopup(false);
            setIsAnimating(true);
        } else {
            setShowPopup(true);
            setIsAnimating(true);
        }
    };

    const printOption = (suggestion, type, index, add = <></>) => {
        return <div
        key={`${type} ${index}`} // Use filename as key if it's unique
        className="partageItem partageHover"
        style={{ justifyContent: "space-between", cursor: 'pointer', flexDirection: 'column', margin: '0 10px 0 0', padding: 0 }}
        onClick={(e) => {
            onSendMessage(suggestion);
        }}
    >
        <div className="divAudio" style={{padding: '13px 20px'}}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{add}{suggestion}</span>
            </div>
        </div>                            
    </div>
    }

    return (
        <div style={{ position: 'relative' }}>
            <div
                className="fleche"
                onClick={handleButtonClick}
                ref={buttonRef}
                style={{cursor:'pointer'}}
            >
                <SuggestionsButton
                    height={50}
                    width={50}
                    className="glow"
                    alt="Suggestions"
                />
            </div>
            {(showPopup || isAnimating) &&
                ReactDOM.createPortal(
                    <div
                        className={`popupDivR ${showPopup ? 'show' : 'hide'}`}
                        ref={popupRef}
                        style={{
                            bottom: calculatePosition().bottom + 'px',
                            right: calculatePosition().right + 'px'
                        }}
                    >
                        <div className="innerPopupRight">
                        {questionsQCM.map((questionQCM, index) => (
                            printOption(questionQCM, "questionQCM", index, <strong>QCM :&nbsp;</strong>)
                        ))}
                        {suggestions.map((suggestion, index) => (
                            printOption(suggestion.suggestion, "suggestion", index)
                        ))}
                        </div>
                    </div>,
                    document.body
                )}
        </div>
    );
};

export default Suggestions;
