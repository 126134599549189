import React from 'react';
import './StatusIndicator.css'; // Ensure you have the corresponding CSS

const StatusIndicator = ({ cours }) => {
  let details = "Construction de la base documentaire";
  let statut = 1; // Default statut

  // Compute 'statut' and 'details' based on 'cours'
  if (!cours) {
    statut = 0;
    details = "Chatbot indisponible";
  } else if (cours.vector_store === null) {
    statut = 1;
    details = "Construction de la base";
  } else if (cours.assistant_id === null) {
    statut = 1;
    details = "Construction de l'assistant";
  } else {
    const waitingFiles = cours.files.filter(file => file.statut === 1).length;
    //console.log(cours.files);
    if (waitingFiles > 0) {
      statut = 1;
      details = `${waitingFiles} fichier${waitingFiles > 1 ? 's' : ''} en attente`;
    } else {
      statut = 2;
      const docCount = cours.files.filter(file => file.statut === 2).length;
      details = `${docCount} document${docCount <= 1 ? '' : 's'} dans la base`;
    }
  }

  let statusMessage = "";
  let lightClass = "";

  // Set 'statusMessage' and 'lightClass' based on 'statut'
  switch (statut) {
    case 0:
      statusMessage = "Erreur !";
      lightClass = "red";
      break;
    case 1:
      statusMessage = "En construction...";
      lightClass = "orange";
      break;
    case 2:
      statusMessage = "Chatbot prêt";
      lightClass = "green";
      break;
    default:
      statusMessage = "Statut inconnu";
  }

  return (
    <div className="status-container">
      <div className="traffic-light">
        <div className={`light ${lightClass}`}></div>
      </div>
      <div className="status-info">
        <h3 className="statutBot">{statusMessage}</h3>
        <p>{details}</p>
      </div>
    </div>
  );
};

export default StatusIndicator;
