// RemoveChatbotModal.js

import React, { useState, useEffect, useRef } from "react";
import './RemoveChatbotModal.css';

const RemoveChatbotModal = ({
    onClose,
    groupId = null,
    assistantId = null,
    handleRemoveChatbot,
    removeChatBotFromGroupe = false,
    existingTargets = []
}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const modalRef = useRef();

    const label = removeChatBotFromGroupe ? "groupe" : "chatbot";

    const paginatedItems = existingTargets.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    const totalPages = Math.ceil(existingTargets.length / itemsPerPage);

    const toggleSelectedItem = (item) => {
        setSelectedItems(prevSelectedItems => {
            if (prevSelectedItems.some(selected => selected.id === item.id)) {
                // Remove the item if it's already selected
                return prevSelectedItems.filter(selected => selected.id !== item.id);
            } else {
                // Add the item if it's not selected
                return [...prevSelectedItems, item];
            }
        });
    };

    const handleSubmit = () => {
        if (selectedItems.length > 0) {
            if (!removeChatBotFromGroupe) {
                selectedItems.forEach(item => {
                    handleRemoveChatbot(item.id, groupId);
                });
            } else {
                selectedItems.forEach(item => {
                    handleRemoveChatbot(assistantId, item.id);
                });
            }
            onClose();
        }
    };

    // Close modal when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="modal-overlay-login">
            <div className="modal-content-access" ref={modalRef} style={{width:'400px'}}>
                <div className="modal-body">
                    <div className="modal-left">
                        <div className="chatbot-list">
                            {paginatedItems.length === 0 && <span style={{ fontStyle: "italic" }}>Aucun {label} à supprimer !</span>}
                            {paginatedItems.map((item) => (
                                <div
                                    key={item.id}
                                    className={`chatbot-item ${selectedItems.some(selected => selected.id === item.id) ? 'selected' : ''}`}
                                    onClick={() => toggleSelectedItem(item)}
                                >
                                    {item.color &&
                                        <div
                                            className="iconeGroupe"
                                            style={{ backgroundColor: item.color, marginRight: 0 }}
                                        />
                                    }
                                    <span className="chatbot-sujet">
                                        {item.sujet || item.nom}
                                    </span>
                                </div>
                            ))}
                        </div>
                        {totalPages > 1 && (
                            <div className="pagination">
                                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                    <button
                                        key={page}
                                        onClick={() => setCurrentPage(page)}
                                        className={page === currentPage ? 'active' : ''}
                                    >
                                        {page}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="modal-buttons" style={{margin: '20px 0 0 0'}}>
                    <button
                        onClick={handleSubmit}
                        disabled={selectedItems.length === 0}
                        className={`login-button ${selectedItems.length === 0 ? 'disabled' : ''}`}
                        style={{width:'150px'}}
                    >
                        {selectedItems.length === 0 ? <>Aucune sélection</> :
                        <>
                        {selectedItems.length} {selectedItems.length > 1 ? label + 's' : label}
                        </>}
                    </button>
                    <button className="signup-button" onClick={onClose} style={{width:'150px'}}>
                        Annuler
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RemoveChatbotModal;
