import {useRef, useState} from 'react';
import DropdownMenu from "../chatbot/DropDownMenu";
import { serverPHP } from '../consts';
import { ReactComponent as PicsUser } from "../pics/user.svg";
import MyAccountModal from './MyAccountModal';
import MyAccountModalEleve from './MyAccountModalEleve';
import { useNavigate } from 'react-router';



const ProfileButton = ({userInfo, setUserInfo, matieres = []}) => {

    const buttonRef = useRef(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [showMyAccount, setShowMyAccount] = useState(false);
    const navigate = useNavigate();




     // Fonction pour gérer la déconnexion
  const handleLogout = () => {
    fetch(serverPHP + '/db/logout.php', {
      method: 'POST',
      credentials: 'include',  // Inclure les cookies dans la requête
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          // Réinitialiser l'état de l'utilisateur après déconnexion
          setUserInfo({ id: null, nom: null, prof: false });
          navigate("/");
        }
      })
      .catch((error) => {
        console.error('Erreur lors de la déconnexion:', error);
      });
  };

    return (
        <>
        <div className="boutonUser" onClick={() => {}} >
          <PicsUser width={35} height={35}
            onClick={(e) => {
              e.stopPropagation();
              setOpenMenu(!openMenu);
            }}
            ref={buttonRef}
          />
        </div>
        <DropdownMenu
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        buttonRef={buttonRef}
        menuItems={[
          { label: 'Mon compte', onClick: () => setShowMyAccount(true) },
          { label: 'Déconnecter', onClick: handleLogout },
        ]}
        openDirection="left"
        decalageX={-100}
      />
      {showMyAccount &&
      <>
      { userInfo.prof ?
        <MyAccountModal isOpen={showMyAccount} onClose={() => setShowMyAccount(false)} userInfo={userInfo} setUserInfo={setUserInfo} matieres={matieres}/>
        :
        <MyAccountModalEleve isOpen={showMyAccount} onClose={() => setShowMyAccount(false)} userInfo={userInfo} setUserInfo={setUserInfo}/>
         }   </>
}
      </>
    )

    
}

export default ProfileButton;